/**
 * @param {string} module
 * @param {string} id
 * @param {Function} callback
 */
export default function reportWindowDeleteSearch(module, id, callback = null) {
    if (typeof id !== 'string' || id === '') {
        this.error('ERROR_RECORD_NOT_SELECTED');
        return;
    }

    this.load();
    this.rest.delete(`search/${module}/${id}`, {}, (returnData) => {
        if (returnData.status !== 'ok') {
            this.error(returnData.description);
        } else if (callback) {
            callback(returnData);
        }
        this.unLoad();
    });
}
