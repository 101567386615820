import checkDuplicityPost from '../action/DetailView/checkDuplicityPost';

export default class detailDefault {
    /**
     * load - call after detailView is loaded
     * @param {any} sAction
     * @param {any} data
     */
    load(sAction, data) {
        // call immediately after detailView is load
    }

    /**
     * update - call after detailView field is update
     * @param {any} sAction
     * @param {any} data
     */
    update(sAction, data) {
        // call immediately after detailView field is update
    }

    /**
     * newSubRecord - call before route sub record
     * @param {any} sAction
     * @param {any} data
     */
    newSubRecord(sAction, data) {
        // call immediately before route sub record
    }

    /**
     * beforeSave - call before Record is save
     * @param {any} sAction
     * @param {any} data
     * @returns {boolean}
     */
    beforeSave(sAction, data) {
    // call bfore Record is save

        return true;
    }

    /**
     * afterSave - call after Record is save
     * @param {any} sAction
     * @param {any} data
     */
    afterSave(sAction, data) {
        // call after Record is save
    }

    /**
     * updateDetailLine - Uprava radku detailu
     * @param {any} sAction
     * @param {any} data
     */
    updateDetailLine(sAction, data) {
        // Uprava radku detailu
    }

    /**
     * checkDuplicity - kontrola duplicity
     * @param {any} sAction
     * @param {any} data
     */
    checkDuplicity(sAction, data) {
        // Kontrola, jestli obsah řádku již neexistuje u jiného záznamu v modulu
        // definováno ve vardefech
        sAction.checkDuplicityPost(data);
    }
}
