/**
 *
 * @param {string} status
 * @returns {string}
 */
export default function getClassColorServis(status) {
    switch (status) {
        case 'new':
            return 'newColor';
        case 'scheduled':
            return 'scheduledColor';
        case 'in_progress':
            return 'inProgressColor';
        case 'realization':
            return 'realizationColor';
        case 'for_invoicing':
            return 'forInvoicingColor';
        case 'solved':
            return 'completedColor';
        case 'not_satisfied':
            return 'notSatisfiedColor';
        default:
            return 'newColor';
    }
}
