import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialDefault from './MaterialDefault';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

export default class InputText extends MaterialDefault {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
        };
        this.containerRef = React.createRef();
    }

    /**
     * handle element focus
     * @param {Event} e event that caused update
     */
    onBlur(e) {
        this.setState({focus: false});
        if (this.props.onBlur !== undefined) {
            this.props.onBlur(e);
        }
    }

    /**
     *
     * @param {Object} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.open === false) {
            this.setState({open: true});
        }

        if (this.props.onKeyDown != null) {
            this.props.onKeyDown(e);
        }
    }

    /**
     * handles pressing field clear button
     * @param {PointerEvent} e click event
     */
    handleClearButton(e) {
        this.props.myRef.current.value = '';
        this.props.onClearButton && this.props.onClearButton(e);
        this.props.myRef.current.focus();
    }

    componentDidMount() {}

    render() {
        const clearButton = this.props.clearButton || false;
        const searchButton = this.props.searchButton || false;
        const attr = this.createAttr('acmInput');
        delete attr.clearButton;
        delete attr.searchButton;
        delete attr.containerStyle;
        attr.onFocus= (e) => {
            const arrayOfTypes=['currency', 'float', 'decimal', 'int'];
            if (arrayOfTypes.includes(this.props.fieldtype)) {
                e.target.select();
            }
            this.props.onFocus && this.props.onFocus();
        };
        attr.onBlur = this.props.onBlur;
        attr.onChange = this.props.onChange;

        if (this.props.freeFormat === true) {
            attr.type = 'text';
            delete attr.freeFormat;
        }

        if (this.props.noDetail != null) {
            delete attr.noDetail;
        }

        return (
            <div
                className={classNames('acmInputContainer', {[this.props.containerClassName]: this.props.containerClassName !== undefined})}
                style={this.props.containerStyle ? this.props.containerStyle : null}
                ref={this.containerRef}
                id={`wrapper_${this.props.id}`}
            >
                {this.props.noDetail ?
                    <TextField {...attr} placeholder={attr.label ?? attr.placeholder} /> :
                    <Input {...attr}
                        placeholder={attr.label ?? attr.placeholder}
                        onKeyDown={(e) => this.onKeyDown(e)}
                    />
                }
                {clearButton && (
                    <span
                        className="icon-closeIconLight clearButton"
                        onClick={(e) => this.handleClearButton(e)}
                    />
                )}
                {searchButton && (
                    <span
                        className="icon-search clearButton searchButton"
                        onClick={(e) => this.props.onSearchButton(e)}
                    />
                )}
            </div>
        );
    }
}

InputText.propTypes = {
    clearButton: PropTypes.bool,
    searchButton: PropTypes.bool,
    onSearchButton: PropTypes.func,
    onClearButton: PropTypes.func,
    containerStyle: PropTypes.object,
    containerClassName: PropTypes.string,
    freeFormat: PropTypes.bool,
    noDetail: PropTypes.any,
};
