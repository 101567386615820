import React, {createContext} from 'react';
import PropTypes from 'prop-types';

export const ReactReportContext = createContext();

export default function ReactReportContextProvider({children, reportType = false, fullscreenId = ''}) {
    const contextData = {reportType, fullscreenId};

    return (
        <ReactReportContext.Provider value={contextData}>
            {children}
        </ReactReportContext.Provider>
    );
}

ReactReportContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    reportType: PropTypes.bool,
    fullscreenId: PropTypes.any,
};
