import detailDefault from '../../detailDefault';
import sAction from "sAction";
import CustomDetailLines from "ROOT/src/components/detailView/CustomLines/CustomDetailLines";

// eslint-disable-next-line camelcase
export default class detailacm_service_order extends detailDefault {
    /**
     * @brief for this field load price lists
     * @type {string}
     */
    static priceListAccountField = 'acm_service_order_accountsaccounts_ida';
    static priceListAccountField2 = 'acm_service_order_accounts_name';

    /**
     * afterSave
     * @param {any} sAction
     */
    afterSave(sAction) {
        if (sAction.isServis()) {
            sAction.updateServisInfo();
            sAction.toast({
                name: 'Uloženo',
            });
        }
    }

    load(sAction, data) {
        const id = sAction.dataGet(`${data.prefix}/id`) ?? '';
        this.filterRelateOptions(sAction, data.prefix);
        this.checkOrderType(sAction, data.prefix);
        const disabledOptionsPosition = ['solved'];
        sAction.disableEnumOptions(
            data.prefix,
            'stav',
            'campaign_state_list',
            disabledOptionsPosition,
        );

        if (sAction.dataGet(`${data.prefix}/fields/longterm_deadline/value`)) {
            sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, false);
        }

        if (sAction.dataGet(data.prefix + '/fields/locked/value') === '1') {
            sAction.dataSet(data.prefix + '/readonly', true);
        }

        if (sAction.isServis()) {
            this.serviceLockFields(sAction, data);

            const servisStatus = sAction.dataGet('appViewCustomData/servisStatus');
            const actOrder = servisStatus.getIn(['activeOrder', 'orderId']);
            const pause = servisStatus.get('pause');
            const orderStatus = sAction.dataGet(data.prefix + '/fields/stav/value');

            const order = sAction.dataGet(data.prefix + '/id');

            this.addButton(sAction, data.prefix, {
                id: 'cancelButton',
                def: {
                    onClick: 'cancelServiceOrder',
                    label: 'LBL_CANCEL_SERVICE_ORDER',
                    id: 'cancelButton',
                    iconClass: 'cancelCircle',
                },
            });

            if (actOrder === order && !pause && orderStatus === 'in_progress') {
                this.addButton(sAction, data.prefix, {
                    id: 'pauseButton',
                    def: {
                        onClick: 'startPause',
                        label: 'LBL_START_PAUSE',
                        id: 'pauseButton',
                        iconClass: 'pause',
                    },
                });

                return;
            }

            if (pause && orderStatus === 'in_progress') {
                this.addButton(sAction, data.prefix, {
                    id: 'pauseButton',
                    def: {
                        onClick: 'stopPause',
                        label: 'LBL_STOP_PAUSE',
                        id: 'pauseButton',
                        iconClass: 'pause',
                    },
                });
            }
        }

        // this.getMachinePhotosStatus(sAction, data.prefix);

        const accountID = detailacm_service_order.getPriceListsAccountID(sAction, data.prefix);
        const shouldDownloadAcmPriceLists = sAction.dataGet(`${data.prefix}/fields/should_download_acm_pricelists/value`);
        if ([1, '1'].includes(shouldDownloadAcmPriceLists) || !id) {
            sAction.reloadPriceLists({
                accountId: accountID,
                recordId: '',
                module: 'acm_service_order'
            }, data.prefix);
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/should_download_acm_pricelists/value', false);
            sAction.dsAdd('set', data.prefix + '/changes/fields/should_download_acm_pricelists', false);
            sAction.dsProcess();
        }
    }

    update(sAction, data) {
        this.filterRelateOptions(sAction, data.prefix);
        const arr = [
            'date_work_end',
            'respon_person',
            'respon_sign',
            'respon_not_found',
        ];

        if (data.field === 'locked' && data.value === 1) {
            this.makePopup(sAction, data.prefix);
        }

        if (arr.includes(data.field) && this.canLock(sAction, data.prefix)) {
            this.makePopup(sAction, data.prefix);
        }

        if (data.field === 'canceled_trip') {
            console.log(data.value);
            if (data.value === 1) {
                this.canceledTrip(sAction, data);
            }
        }

        if (data.field === 'acm_service_order_lines_acm_machinesacm_machines_ida') {
            sAction.rest.post('getMachineMotohours', data.value, (returnData) => {
                if (returnData.status) {
                    this.updateMotohours(returnData.message.data, data.value, data.prefix, sAction);
                }
            }, false);
        } else if (data.field === 'order_type') {
            this.checkOrderType(sAction, data.prefix);
        } else if (data.field === 'longterm_deadline') {
            if (data.value) {
                sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, false);
            } else {
                sAction.dataSet(`${data.prefix}/fields/acm_service_order_accounts_name/def/required`, true);
            }
        } else if (data.field === 'respon_person' && data.value) {
            sAction.dataSet(`${data.prefix}/fields/customer_person/value`, data.value);
            sAction.dataSet(`${data.prefix}/changes/fields/customer_person`, data.value);
        } else if(data.field === detailacm_service_order.priceListAccountField) {
            if (data.value.id) {
                sAction.reloadPriceLists({
                    accountId: data.value.id,
                    recordId: sAction.dataGet(`${data.prefix}/id`),
                    module: 'acm_service_order'
                }, data.prefix);
            }
        } else if(data.field === detailacm_service_order.priceListAccountField2){

        }
    }

    static getPriceListsAccountID(sAction, prefix){
        return sAction.dataGet(`${prefix}/fields/${detailacm_service_order.priceListAccountField}/value`) ||
            sAction.dataGet(`${prefix}/fields/${detailacm_service_order.priceListAccountField2}/def/id_value`);
    }

    /**
     * add button to detail
     * @param {any} sAction
     * @param {string} prefix
     * @param {object} buttonData
     */
    addButton(sAction, prefix, buttonData) {
        // check if button already exists
        const buttons = sAction.dataGet(prefix + '/buttons');
        // if button with same id exists, do nothing
        if (buttons.find((button) => button.get('id') === buttonData.id)) {
            console.log('>> button already exists'); // eslint-disable-line no-console
            return;
        }
        sAction.dataAdd(
            prefix + '/buttons',
            buttonData,
            'DetailButton',
        );
    }

    /**
   *
   * @param sAction
   * @param prefix
   * @param valueOfState
   */
    updateOrderState(sAction, prefix, valueOfState) {
        sAction.dataSet(prefix + '/fields/stav/value', valueOfState);
        sAction.dataSet(prefix + '/changes/fields/stav', valueOfState);
    }

    /**
   * kontroluje jestli se muze zamknout
   * responNotFound ze storu vraci budto undefined nebo "0". value && "0" je podle js true. Takze se musi
   * responNotFound checkovat na === 1. Pokud je vyplnen podpis(responSign) nesmi se kontrolovat responNotFound
   * @param sAction
   * @param prefix
   * @returns {boolean}
   */
    canLock(sAction, prefix) {
        const orderState = sAction.dataGet(prefix + '/fields/stav/value');
        const dateWorkEnd = sAction.dataGet(prefix + '/fields/date_work_end/value');
        const responPerson = sAction.dataGet(
            prefix + '/fields/respon_person/value',
        );
        const responSign = sAction.dataGet(prefix + '/fields/respon_sign/value');
        const responNotFound = sAction.dataGet(
            prefix + '/fields/respon_not_found/value',
        );

        if (orderState === 'new' || orderState === 'in_progress' || orderState === 'scheduled') {
            if (responSign) {
                return !!(dateWorkEnd && responPerson);
            } else if (responNotFound === 1) {
                return !!(dateWorkEnd && responPerson);
            }
        }
    }

    /**
     * make popup
     * @param {any} sAction
     * @param {string} prefix
     */
    makePopup(sAction, prefix) {
        const data = {
            header: sAction.translate('LBL_LOCK_DETAIL'),
            buttons: [
                {
                    label: sAction.translate('LBL_CLOSE_POPUP'),
                    callback: () => {
                        sAction.dsClear();
                        sAction.dsAdd('set', prefix + '/fields/locked/value', 0);
                        sAction.dsAdd('set', prefix + '/changes/fields/locked', 1);
                        sAction.dsProcess();
                        sAction.popupHide();
                    },
                },
                {
                    label: sAction.translate('LBL_LOCK_DETAIL'),
                    callback: () => {
                        sAction.popupHide();
                        this.lockDetail(sAction, prefix);
                        this.prepareSave(sAction, prefix);
                    },
                },
            ],
        };

        sAction.popup(sAction.translate('LBL_LOCK_PHRASE'), data);
    }

    /**
     * lock detail
     * @param {any} sAction
     * @param {string} prefix
     */
    lockDetail(sAction, prefix) {
        sAction.dsClear();
        sAction.dsAdd('set', prefix + '/readonly', true);
        sAction.dsAdd('set', prefix + '/fields/locked/value', 1);
        sAction.dsAdd('set', prefix + '/changes/fields/locked', 1);
        sAction.dsProcess();
    }

    /**
     * prepare save
     * @param {any} sAction
     * @param {string} prefix
     */
    prepareSave(sAction, prefix) {
        const saveData = {
            canSave: true,
            id: sAction.dataGet(prefix + '/id'),
            module: sAction.dataGet(prefix + '/module'),
            prefix: prefix,
            type: sAction.dataGet(prefix + '/type'),
            view: sAction.dataGet('conf/view'),
        };

        sAction.saveRecord(saveData);
    }
    /**
     *
     * @param {any} sAction
     * @param {any} data
     */
    serviceLockFields(sAction, data) {
        sAction.dsClear();
        const lockedFields = [
            'stav',
            'order_type',
            'name',
            'acm_service_order_accounts_1_name',
            'exec_address',
            'acm_service_order_contacts_1_name',
            'customer_phone',
            'customer_email',
            'date_work_begin',
            'date_work_end',
            'locked',
        ];
        lockedFields.forEach((field) => {
            sAction.dsAdd(
                'set',
                data.prefix + '/fields/' + field + '/def/readonly',
                true,
            );
        });
        sAction.dsProcess();
    }

    /**
   *
   * @param motohours
   * @param seekedLineLinkId
   * @param prefix
   * @param sAction
   */
    updateMotohours(motohours, seekedLineLinkId, prefix, sAction) {
        const lines = sAction.dataGet(prefix + '/customData/customLines/lines');

        lines.forEach((line, index) => {
            if (line.get('acm_service_order_lines_acm_machinesacm_machines_ida') === seekedLineLinkId) {
                sAction.dataSet(prefix + '/customData/customLines/lines/' + index + '/motohours', motohours);
            }
        });
    }

    /**
   *
   * @param sAction
   * @param prefix
   */
    filterRelateOptions(sAction, prefix) {
    // Nastavi filtry dle coripo/cemat#223 - filtruje se v radcich stroje relate podle zakanzik - koncovy
    // a kontakty v Objednatel - osoba  podle Objednatel - osoba
        const objFirma = sAction.dataGet(
            prefix + '/fields/acm_service_order_accounts_name/def/id_value',
        );
        const zakFirma = sAction.dataGet(
            prefix + '/fields/acm_service_order_accounts_1_name/def/id_value',
        );
        const objPobocka = sAction.dataGet(
            prefix + '/fields/acm_service_order_accounts_2_name/def/id_value',
        );

        let machineFilterId = objFirma;

        if (objPobocka) {
            machineFilterId = objPobocka;
        } else if (zakFirma) {
            machineFilterId = zakFirma;
        }

        const defFilterMachines = [
            {
                module: 'Accounts',
                name: 'acm_machines_accounts_name',
                operandType: 'relate',
                parentId: machineFilterId,
                relName: 'acm_machines_accounts',
                relationship: ['acm_machines_accounts'],
                type: 'eq',
            },
        ];
        const filterUserTechnicians = [
            {
                type: 'eq',
                value: 1,
                field: 'technician',
            },
        ];
        sAction.dsClear();
        sAction.dsAdd(
            'set',
            prefix +
        '/customData/customLines/moduleData/acm_service_order_lines_acm_machines/defaultFilter',
            defFilterMachines,
        );
        sAction.dsAdd(
            'set',
            prefix +
        '/fields/acm_service_order_contacts_name/def/defaultFilter/0/parentId',
            objFirma,
        );
        sAction.dsAdd(
            'set',
            prefix +
        '/customData/customLines/moduleData/acm_service_order_lines_acm_machines_name/defaultFilter',
            defFilterMachines,
        );
        sAction.dsAdd(
            'set',
            prefix +
        '/fields/assigned_user_name/def/defaultFilter',
            filterUserTechnicians,
        );

        sAction.dsAdd(
            'set',
            prefix +
        '/customData/customLines/moduleData/name/defaultFilter',
            [],
        );
        sAction.dsProcess();
    }

    /**
     *
     * @param sAction
     * @param prefix
     */
    checkOrderType(sAction, prefix) {
        if (sAction.dataGet(`${prefix}/fields/order_type/value`) === 'internal_service') {
            sAction.dsClear();
            sAction.dsAdd('set', `${prefix}/fields/customer_email/def/required`, false);
            sAction.dsAdd('set', `${prefix}/fields/customer_phone/def/required`, false);
            sAction.dsProcess();
            return;
        }
        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/customer_email/def/required`, true);
        sAction.dsAdd('set', `${prefix}/fields/customer_phone/def/required`, true);
        sAction.dsProcess();
    }

    /**
   *
   * @param sAction
   * @param data
   */
    async updateDetailLine(sAction, data) {

        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    let way = data.prefix.split('/'); // data.prefix === view/customData/customLines/lines/4
                    let prefix = way[0];
                    way.pop();
                    way = way.join('/');
                    const lines = sAction.dataGet(way); // get all lines and actual count
                    let count = lines.size ?? lines.length;

                    const ret = await sAction.getProductTemplate(data.value.id, 'prodName', prefix, 0, 'acm_service_order');
                    const lineItem = ret?.specific
                    if(!lineItem){
                        return;
                    }
                    this.processProductTemplateLine(sAction, data, lineItem);
                    sAction.dsClear();
                    ret?.boundItems?.forEach((item, key) => {
                        sAction.dsClear();
                        CustomDetailLines.addCustomLine(data.def, data.moduleData, prefix, count, false);
                        const itemWay = `${way}/${count}`;
                        // Process product template on acm_service_order - on place of 4, sorry, search this string for all 4 places
                        sAction.dsAdd('set', `${itemWay}/product_templates_id`, detailacm_service_order.handleTemplateID(item));
                        sAction.dsAdd('set', `${itemWay}/kod`, item.mft_part_num);
                        sAction.dsAdd('set', `${itemWay}/mj`, item.mj);
                        sAction.dsAdd('set', `${itemWay}/cena_bez_dph_kus`, detailacm_service_order.getPriceFromTemplate(item));
                        sAction.dsAdd('set', `${itemWay}/pricing_map`, detailacm_service_order.handlePricingMap(item.pricing_map ));
                        sAction.dsAdd('set', `${itemWay}/name`, item.name);
                        sAction.dsAdd('set', `${itemWay}/product_templates_name`, item.name);
                        sAction.dsProcess();
                        count++
                    });
                }
                break;
        }
    }

    static getPriceFromTemplate(item){
        return item?.pricing_map?.price_after_discount ?? item?.price_after_discount ?? item?.list_price ?? '666611116666';
    }

    /**
     * Because of different data return from searching by autocomplete and by popup
     * 
     * @param {object} lineItem data from sAction.getProductTemplate
     * @return {string|null}
     */
    static handleTemplateID(lineItem){
        return lineItem.product_template_id ?? lineItem.product_templates_id ?? lineItem.id;
    }

    /**
     * Array
     *
     * @param pricingMap
     */
    static handlePricingMap(pricingMap){
        if(pricingMap == null){
            return JSON.stringify('');
        }
        if(typeof pricingMap  === 'object' && Object.keys(pricingMap).length > 0){
            return JSON.stringify(pricingMap);
        }
        return JSON.stringify('');;
    }

    /**
     * Process product template on acm_service_order - on place of 4, sorry, search this string for all 4 places
     *
     * @param sAction
     * @param data
     * @param lineItem
     */
    processProductTemplateLine(sAction, data, lineItem){
        data.saveField(detailacm_service_order.handleTemplateID(lineItem), 'product_templates_id', true);
        data.saveField(lineItem.mft_part_num, 'kod', true);
        data.saveField(lineItem.mj, 'mj', true);
        data.saveField(detailacm_service_order.getPriceFromTemplate(lineItem), 'cena_bez_dph_kus', true);
        data.saveField(detailacm_service_order.handlePricingMap(lineItem.pricing_map ), 'pricing_map', true);
        data.saveField(lineItem.name, 'name', true);
        data.saveField(lineItem.name, 'product_templates_name', true);
    }

    /**
   *
   * @param sAction
   * @param data
   * @param searchData
   * @param fields
   */
    doQuickSearch(sAction, data, searchData, fields) {
        sAction.load();
        sAction.quickSearch(searchData, (returnData) => {
            sAction.dsClear();
            fields.forEachObject((templateName, lineName) => {
                data.saveField(returnData[0][templateName], lineName, true);
            });
            sAction.dsProcess();
            sAction.unLoad();
        });
    }

    /**
   *
   * @param sAction
   * @param prefix
   */
    getProductFilterByType(sAction, prefix) {
        switch (sAction.dataGet(prefix + '/fields/order_type/value')) {
            case 'pneu_service':
                return 1;
            case 'pz_service':
                return 2;
            case 'otr_service':
                return 3;
            case 'internal_service':
                return 1;
                // return 4; zmena z issue coripo/cemat#745 - interní zakázka, aby mohli servisní mechanici vybírat se stejných karet jako je služba pneu
            default:
                return 0;
        }
    }

    /**
   *
   * @param key
   * @param value
   * @param way
   * @param index
   * @param sAction
   */
    saveValueCustomLine(key, value, way, index, sAction) {
        sAction.dsClear();
        sAction.dsAdd('set', way + '/customData/customLines/lines/' + index + '/' + key, value);
        sAction.dsAdd('set', way + '/changes/forceChange', true);
        sAction.dsProcess();
    }

    /**
   *
   * @param sAction
   * @param data
   */
    canceledTrip(sAction, data) {
        sAction.confrim(sAction.translate('LBL_SET_TRIP_AS_CANCELED_TRIP', 'acm_service_order'), () => {
            const saveData = {
                canSave: true,
                id: sAction.dataGet(data.prefix + '/id'),
                module: sAction.dataGet(data.prefix + '/module'),
                prefix: data.prefix,
                type: sAction.dataGet(data.prefix + '/type'),
                view: sAction.dataGet('conf/view'),
            };

            sAction.popupHide();
            sAction.saveRecord(saveData);
        });
    }

    // todo: this part reworked via afterFetch - I vote to delete it
    // /**
    //  * Zjistuje jestli je u strojů v detaillines nahraná fotka a podle toho nastavuje ikonku
    //  * @param sAction
    //  * @param prefix
    //  */
    // getMachinePhotosStatus(sAction, prefix) {
    //     // if view is not servis return
    //     if (sAction.deviceType !== "servis") {
    //         return;
    //     }
    //
    //     const lines = sAction.dataGet(prefix + "/customData/customLines/lines");
    //     const machineIds = [];
    //
    //     lines.forEach((line) => {
    //         const id = line.get("acm_service_order_lines_acm_machinesacm_machines_ida");
    //         if (id) {
    //             if(!machineIds.includes(id)){
    //                 machineIds.push(id)
    //             }
    //         }
    //     });
    //
    //     // call to rest getMachinesPhotoStatus post with machineIds
    //     sAction.rest.post("getMachinesPhotoStatus", machineIds, returnData => {
    //         if(returnData.status) {
    //             const photosStatus = returnData.message.data;
    //             this.setMachinePhotosStatus(sAction, prefix, photosStatus);
    //         }
    //     }, false)
    // }

    // setMachinePhotosStatus(sAction, prefix, photosStatus) {
    //     const lines = sAction.dataGet(prefix + "/customData/customLines/lines");
    //
    //     lines.forEach((line, index) => {
    //         const id = line.get("acm_service_order_lines_acm_machinesacm_machines_ida");
    //         if (id) {
    //             const status = photosStatus[id];
    //             sAction.dataSet(prefix + "/customData/customLines/lines/" + index + "/photosStatus", status)
    //         }
    //     })
    // }
}
