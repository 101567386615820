import React, {PureComponent} from 'react';
import CustomReport from '../../../CustomReport/Report';
import PropTypes from 'prop-types';

export default class DetailDashboard extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {data, id, way, type} = this.props;
        let renderElement = null;
        if (type === 'customReport') {
            renderElement = (
                <CustomReport
                    data={data?.get('panelData')}
                    way={way}
                    reportId={data.get('reportId')}
                    paramData={{recordId: id, isDashboard: true}}
                    isDashboard={true}
                />
            );
        }

        return (
            (id ?
                <div className="detailDashboardContainer">
                    {renderElement}
                </div> : null)
        );
    }
}

DetailDashboard.propTypes = {
    id: PropTypes.string,
    way: PropTypes.string.isRequired,
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
};
