import React, {useEffect, Suspense, useState} from 'react';
import PropTypes from 'prop-types';
import DetailApiButtons from '../DetailViewFields/InputFields/Api/DetailApiButtons';
import sAction from 'sAction';
import Loader from 'ROOT/src/components/loader';

/**
 * @param {string} selector
 * @param {string|HTML} help
 * @param {string} header
 */
function showInfo(selector, help, header) {
    sAction.popper({
        selector: selector,
        content: help,
        header: header,
        className: 'detailViewField',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    });
}

export default function DetailViewField(props) {
    /**
     * @param {Event} e
     * @param {object} data
     */
    function show(e, data) {
        e.stopPropagation();
        const prefix = props.prefix;
        const type = sAction.dataGet(prefix + '/type');
        if (type !== 'rightPanel') {
            sAction.rightPanelDetail(data.module, data.id);
        }
    }
    const [Field, setField] = useState(React.lazy(() => import(`../DetailViewFields/ValuesFields/Varchar/Varchar.js`)));
    // const activeTab = sAction.dataGet(`${props.prefix}/activeTab`); // does not work 1989 tabulator fix
    const activeTab = sAction.dataGet(props.prefix); // works

    const [fullWidthField, setFullWidthField] = useState(false);

    useEffect(() => {
        if (sAction.isMobile() && type === 'Text') {
            setFullWidthField(true);
        }

        if (props.actEdit) {
            setField(React.lazy(() => import(`../DetailViewFields/InputFields/${type}/${type}.js`)));
        } else {
            setField(React.lazy(() => import(`../DetailViewFields/ValuesFields/${type}/${type}.js`)));
        }
    }, [props.actEdit, activeTab]);

    if (!props.def) {
        return null;
    }
    let type = (props.def.get('type')?.charAt(0).toUpperCase() + props.def.get('type')?.slice(1)) || 'Varchar';
    let showDetailApi = props.def.get('showDetailApi');
    if (showDetailApi === undefined && type === 'Phone') {
        showDetailApi = 'phoneApi';
    }
    if (type === 'AcmAddress' && !props.actEdit) {
        showDetailApi = 'OpenOpenStreetMap';
    }
    let showApiButtons = showDetailApi && showDetailApi.indexOf('Api') !== -1;
    if (props.acl && props.acl.get('edit') === false) {
        showApiButtons = false;
    }
    if (showApiButtons) {
        if (showDetailApi !== 'ApiStreet' && showDetailApi !== 'phoneApi') {
            type = showDetailApi;
        }
    }

    let help = props.def.get('help') ?? false;
    help = help ? sAction.translate(help, props.module) : help;
    const style = props.group ? {width: '100%'} : {width: `${100 / (props.panelColumns || 2) * (props.fieldColumns || 1)}%`};
    if (props.def.get('visible') === false) {
        return '';
    }
    const newRecord = false;

    // show popper with field definition on click if debug is true
    const debugProps = sAction.getStorage('debug') ? {
        id: `${props.name}_field_label`,
        onClick: () => {
            /**
             * @param {any} value
             * @param {string} tag
             * @returns {string}
             */
            const getValue = (value) => {
                if (value == null) {
                    return '';
                }
                // handle immutable objects
                if (value && value.toJS) {
                    return <table>{getValue(value.toJS())}</table>;
                } else if (Array.isArray(value)) {
                    return value.map((v) => getValue(v));
                } else if (typeof value === 'object') {
                    return <>
                        <tr>{Object.keys(value).map((key, i) => <th key={i}>{key}:</th>)}</tr>
                        <tr>{Object.values(value).map((v, i) => <td key={i}>{getValue(v)}</td>)}</tr>
                    </>;
                }
                return String(value);
            };

            const rows = [];

            props.def.map((value, key) => {
                rows.push(<tr key={key}><td>{key}:</td><td>{getValue(value)}</td></tr>);
            });
            const content = (
                <React.Fragment>
                    <table className='simpleForm'>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </React.Fragment>
            );
            sAction.popper({
                header: props.labelValue,
                content,
                selector: `#${props.name}_field_label`,
                className: 'debug',
            });
        },
    } : {};

    let ExtraRender = null;
    if (props.def.get('extraRender')) {
        ExtraRender = React.lazy(() => import(`../DetailViewFields/ValuesFields/${type}/${props.def.get('extraRender')}.js`));
        style.flexWrap = 'wrap';
    }
    // calculate label width based on relative width of the field in the panel
    const labelWidth = fullWidthField? 100 : (sAction.isMobile()? 40 : 25 * Math.pow(props.fieldColumns / props.panelColumns, -0.5));

    return props.def.get('displayIfNew') === false && props.id === null ? null : (
        <div
            className={'detailViwRow' + (fullWidthField ? ' fullWidthField' : '')}
            style={style}
        >
            <div className="DetailviewLabel" style={{width: `${labelWidth}%`}}>
                {props.def.get('required') && <span className="requiredMobile"></span>}
                <label
                    dangerouslySetInnerHTML={{__html: props.labelValue}}
                    {...debugProps}
                />
                {props.def.get('required') && <span className="required">* </span>}
                {help && (<div id={props.name + '_help'}
                    className="icon-info detailviewInfoIcon"
                    onClick={() => showInfo('#' + props.name + '_help', help, props.labelValue)}/>
                )}
            </div>
            <div className={props.edit ? 'DetailViewValue pointerClass' : 'DetailViewValue'} style={{width: `${100 - labelWidth}%`}}>
                <div className={showDetailApi ? 'detailApi__field' : ''}>
                    <Suspense fallback={(<Loader />)}>
                        <Field
                            edit={() => sAction.fieldToEdit({
                                way: props.way,
                                name: props.name,
                                prefix: props.prefix,
                                type: props.def.get('type'),
                            })}
                            show={(e, data) => show(e, data)}
                            newRecord={newRecord}
                            {...props}
                            data={props}
                        />
                    </Suspense>
                    {props.def.get('isInvalid') ? (
                        <span className="errorMsg">
                            {sAction.translate('LBL_REQUIRED_FIELD_EMPTY', 'Calendar')}
                        </span>
                    ) : null}
                    {showDetailApi && (
                        <DetailApiButtons
                            type={showDetailApi}
                            prefix={props.prefix}
                            way={props.way}
                            name={props.name}
                            value={props.value}
                            def={props.def}
                        />
                    )}
                </div>
                <input type="hidden" id={props.name + '_hidden'} value={props.value == null ? '' : props.value}/>
            </div>
            {ExtraRender && !props.actEdit && (
                <div className="extraRender">
                    <Suspense fallback={(<Loader />)}>
                        <ExtraRender {...props}/>
                    </Suspense>
                </div>
            )}
        </div>
    );
}

DetailViewField.propTypes = {
    prefix: PropTypes.any, // napr: view
    module: PropTypes.any,
    edit: PropTypes.any,
    way: PropTypes.any, // napr: view/field/${field}
    percent: PropTypes.any, // TODO netusim co je, mozna pujde smazat
    key: PropTypes.any,
    rowWay: PropTypes.any,
    id: PropTypes.any,
    readonly: PropTypes.any,
    acl: PropTypes.any,
    mode: PropTypes.any,
    attemptedSave: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    actEdit: PropTypes.any,
    customStyle: PropTypes.any,
    customClass: PropTypes.any,
    def: PropTypes.any,
    events: PropTypes.any,
    selectForEdit: PropTypes.any,
    group: PropTypes.bool,

    extraStyle: PropTypes.any,
    labelValue: PropTypes.any,
    fieldColumns: PropTypes.number.isRequired,
    panelColumns: PropTypes.number.isRequired,
};
