import React, {useState, createContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import useDetailView from '../hooks/EditView/useEditView';
import useFields from '../hooks/EditView/useFields';
import sAction from 'sAction';
import useSaveEditView from 'ROOT/src/hooks/EditView/useSaveEditView';

export const EditViewContext = createContext({});

export default function EditViewContextProvider({children}) {
    const levels = [
        {value: 'intern', label: sAction.translate('LBL_EV_LEVEL_INTERN', 'EditView')},
        {value: 'custom', label: sAction.translate('LBL_EV_LEVEL_CUSTOM', 'EditView')},
        {value: 'third', label: sAction.translate('LBL_EV_LEVEL_THIRD', 'EditView')},
    ];

    const [dragData, setDragData] = useState({isDragging: false});
    const [activeTab, setActiveTab] = useState(0);
    const [selectedGroupingOpt, setSelectedGroupingOpt] = useState(['/default']);
    const [module, setModule] = useState('');
    const [level, setLevel] = useState(sAction.defaultEditViewLevel());
    const [fieldsWhitelist, setFieldsWhitelist] = useState([]);
    const [errorTabs, setErrorTabs] = useState([]);
    const [searchedFields, setSearchedFields] = useState([]);
    const [isThirdLevelForModule, setIsThirdLevelForModule] = useState(false);
    const view = useDetailView();
    const {fields, getField, getGroupFields, setFields, setField, removeFieldFromLeftPanel, editViewSaveField} = useFields();

    useEffect(() => {
        if (sAction.canAccessEditView()) {
            sAction.rest.fetchData('getFieldsWhitelist', 'GET', null, false).then(({data}) => {
                setFieldsWhitelist(data);
            }).catch(({text}) => {
                sAction.error(sAction.translate(text));
            });
        }
    }, []);

    /**
     *
     */
    const checkThirdLevel = () => {
        if (module) {
            const displayView = sAction.getViewName();
            sAction.rest.fetchData(`checkThirdLevel/${module}/${displayView}`, 'GET', null, false).then(({data}) => {
                setIsThirdLevelForModule(data.exists);
            }).catch(({text}) => {
                sAction.error(sAction.translate(text));
            });
        }
    };

    useEffect(() => {
        checkThirdLevel();
    }, [module]);

    /**
     * @param {object} event
     * @see https://docs.dndkit.com/api-documentation/context-provider
     */
    const handleDragStart = (event) => {
        const view1 = view.getView();
        const contID = event.active.data.current?.sortable?.containerId.split('/');
        const index = event.active.data.current?.sortable?.index;
        let item;
        if (event.active.data?.current?.type === 'ROW') {
            item = view1[contID[0]]['panels'][contID[1]]['rows'][index]['fields'];
        } else if (event.active.data?.current?.type === 'PANEL') {
            item = view1[contID]['panels'][index];
        } else if (event.active.data?.current?.type === 'FIELD') {
            const id = event.active?.id.split('/');
            item = id.length === 4 ? view1[id[0]]['panels'][id[1]]['rows'][id[2]]['fields'][id[3]] : {
                columns: 1,
                id: event.active.id,
                name: event.active.id,
            };
        }
        setDragData({
            isDragging: true,
            id: event.active.id,
            type: event.active.data?.current?.type,
            label: event.active.data?.current?.label,
            item: item,
            active: {
                index: event.active.data.current.sortable?.index,
                containerId: event.active.data.current.sortable?.containerId,
            },
            path: [1, 2], // TODO opravit po mne, mela by byt cesta. Nevim jestli je potreba vlastne
            swapped: false,
        });
    };

    /**
     * Set isDragging to false - on drag end or error
     */
    const handleDragCancel = () => {
        setDragData({
            isDragging: false,
        });
    };

    /**
     * @param {object} root0 DnD event handler
     * @param {object} root0.active
     * @param {object} root0.over
     * @see https://docs.dndkit.com/api-documentation/context-provider
     */
    const handleDragOver = ({active, over}) => {
        const activeContainer = (active.data.current?.sortable?.containerId ?? dragData.active.containerId)?.split('/');
        const overContainer = over.data.current?.sortable?.containerId.split('/') ?? [];
        switch (dragData.type) {
            case 'ROW':
                if (overContainer.length === 2 && overContainer.toString() !== activeContainer.toString()) {
                    activeContainer.push(active.data.current?.sortable?.index ?? dragData.active.index);
                    overContainer.push(0);
                    view.moveRow(true, activeContainer, overContainer);
                    setDragData({...dragData, active: {containerId: `${overContainer[0]}/${overContainer[1]}`, index: 0}});
                    break;
                } else if (
                    overContainer.length === 1 &&
                    over.data.current?.sortable?.index !== undefined &&
                    over.data.current?.sortable?.index !== -1
                ) {
                    overContainer.push(over.data.current?.sortable?.index);
                    if (overContainer.toString() !== activeContainer.toString()) {
                        activeContainer.push(active.data.current?.sortable?.index ?? dragData.active.index);
                        overContainer.push(0);
                        view.moveRow(true, activeContainer, overContainer);
                        setDragData({...dragData, active: {containerId: `${overContainer[0]}/${overContainer[1]}`, index: 0}});
                    }
                }
                break;
            case 'PANEL':
                if (over.data.current?.type === 'FIELD' ||
                    over?.id === 'LEFT_PANEL' ||
                    over?.data?.current?.sortable?.containerId === 'LEFT_PANEL'
                ) {
                    break;
                }
                overContainer.length === 0 ? overContainer.push(over.id) : {};
                if (overContainer.length === 1 && overContainer.toString() !== activeContainer.toString()) {
                    activeContainer.push(active.data.current?.sortable?.index ?? dragData.active.index);
                    overContainer.push(0);
                    view.movePanel(true, activeContainer, overContainer);
                    setDragData({...dragData, active: {containerId: overContainer[0], index: 0}});
                }
                break;
            case 'FIELD':
                break;
            default:
                break;
        }
    };

    /**
     * DnD event handler
     * @param {object} root0
     * @param {object} root0.over
     * @param {object} root0.active
     * @see https://docs.dndkit.com/api-documentation/context-provider
     */
    const handleDragEnd = ({over, active}) => {
        const resetDragData = {
            isDragging: false,
            id: null,
            type: null,
            containerId: null,
            index: null,
            swapped: false,
            item: null,
        };
        if (active?.id === over?.id) {
            setDragData(resetDragData);

            return;
        }
        let from = [];
        let to = [];
        switch (dragData.type) {
            case 'ROW':
                from = active.data.current?.sortable?.containerId.split('/') || [];
                from.push(active.data.current.sortable?.index);
                if (over?.data?.current?.sortable?.containerId === 'LEFT_PANEL' || over?.id === 'LEFT_PANEL') {
                    view.removeRow(from);
                } else {
                    to = over?.data.current?.sortable?.containerId.split('/') || [];
                    to.push(over?.data.current?.sortable?.index);
                    view.moveRow(false, from, to);
                }
                break;
            case 'PANEL':
                from = [active.data.current.sortable?.containerId, active.data.current.sortable?.index];
                if (over?.data?.current?.sortable?.containerId === 'LEFT_PANEL' || over?.id === 'LEFT_PANEL') {
                    view.removePanel(from);
                } else {
                    to = [over?.data.current?.sortable.containerId, over?.data.current?.sortable.index];
                    view.movePanel(false, from, to);
                }
                break;
            case 'TAB':
                if (over.data?.current.type !== 'TAB') {
                    break;
                }
                view.moveTab(active.data.current.sortable.index, over.data.current.sortable.index);
                break;
            case 'FIELD':
                from = active.data.current?.sortable?.containerId === 'LEFT_PANEL' ? ['LEFT_PANEL'] : active.id.split('/');
                to = over?.data?.current?.sortable?.containerId === 'LEFT_PANEL' ? ['LEFT_PANEL'] : over?.id.split('/');
                if (over?.data?.current?.type === 'FIELD') {
                    view.moveField(from, to, active?.id);
                }
                break;
            default:
                break;
        }
        setDragData(resetDragData);
    };

    /**
     * @param {*} fieldData
     * @param {*} newField
     * @param {boolean} reloadWindow
     * @param {string} redirectToUrl
     * @param {Function} additionalSetFields
     * @param {object} additionalFields
     * @param {string} fieldModule
     * @param {string} fieldLevel
     */
    const saveField = (
        fieldData,
        newField,
        reloadWindow = false,
        redirectToUrl = '',
        additionalSetFields = null,
        additionalFields = {},
        fieldModule = null,
        fieldLevel = null,
    ) => {
        editViewSaveField(
            fieldModule ?? module,
            fieldLevel ?? level,
            fieldData,
            newField,
            reloadWindow,
            redirectToUrl,
            additionalSetFields,
            additionalFields,
        );
    };

    /**
     *
     * @param {object} fieldData
     * @param {boolean} fromLeftPanel
     */
    const deleteField = (fieldData, fromLeftPanel = false) => {
        sAction.confrim(sAction.translate('LBL_EV_DELETE_FIELD_CONFIRM'), () => {
            const fieldName = fieldData?.name;
            sAction.load();
            sAction.rest.fetchData('editView/deleteField', 'DELETE', {
                fieldName: fieldName,
                module: module,
            }, false).then((res) => {
                if (res?.text) {
                    sAction.toast({
                        name: sAction.translate(res?.text),
                        description: ' ',
                    });
                }

                useSaveEditView(view.getView(), module, level, selectedGroupingOpt, false);

                if (!fromLeftPanel) {
                    const fieldPath = fieldData.id.split('/');
                    view.removeField(fieldPath);
                }

                removeFieldFromLeftPanel(fieldName);
                sAction.unLoad();
                sAction.popupHide();
            }).catch((error) => {
                console.error(error);
                if (error?.text) {
                    sAction.error(sAction.translate(error?.text));
                }
                sAction.unLoad();
                sAction.popupHide();
            });
        });
    };

    const EditViewContextData = {
        activeTab,
        setActiveTab,
        dragData,
        setDragData,
        isAdmin: sAction.isAdmin(),
        module,
        setModule,

        // Fields Handler
        fields,
        getField,
        getGroupFields,
        setFields,
        setField,
        saveField,
        fieldsWhitelist,

        // EditView struct things
        view: view.getView(),
        initView: view.initView,
        addTab: view.addTab,
        addPanel: view.addPanel,
        addRow: view.addRow,
        moveRow: view.moveRow,
        removeRow: view.removeRow,
        renamePanel: view.renamePanel,
        removePanel: view.removePanel,
        removeTab: view.removeTab,
        expandField: view.expandField,
        collapseField: view.collapseField,
        editTab: view.editTab,
        editPanelColumns: view.editPanelColumns,
        loadView: view.loadView,

        // Support opts
        selectedGroupingOpt,
        setSelectedGroupingOpt,
        level,
        levels,
        setLevel,

        // DnD events
        handleDragStart,
        handleDragCancel,
        handleDragOver,
        handleDragEnd,
        errorTabs,
        setErrorTabs,
        searchedFields,
        setSearchedFields,
        deleteField,

        // Check third level on detail and list
        isThirdLevelForModule,
        checkThirdLevel,
    };

    return (
        <EditViewContext.Provider value={EditViewContextData}>
            {children}
        </EditViewContext.Provider>
    );
}

EditViewContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
