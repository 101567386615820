import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line camelcase
export default function Currency_id(props) {
    let lineRender = null;
    try {
        lineRender = props.currencies[props.value].symbol;
    } catch (e) {
        console.warn(props.currencies);
        console.warn('Nenalezna měna s ID: ' + props.value);
        lineRender = props.value;
    }
    return (
        <div id={props.fieldId} className={'newListViewLine '} onClick={props.onClick}>
            <div className={'w100 withIconContainer'}>
                <div>{lineRender}</div>
                {props.relationFieldEditIcon}
            </div>
        </div>
    );
}

// eslint-disable-next-line camelcase
Currency_id.propTypes = {
    currencies: PropTypes.any,
    fieldId: PropTypes.any,
    onClick: PropTypes.any,
    relationFieldEditIcon: PropTypes.any,
    value: PropTypes.any,
};
