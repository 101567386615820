import React from 'react';
import PureComponent from '../../pure';
import InputText from '../../formElements/InputText';
import Checkbox from '../../formElements/TickBox';
import Select from '../../formElements/Select';
import Relate from '../../formElements/Relate';
import MultiSelect from '../../formElements/MultiSelect';

import sAction from 'sAction';
import AcmDate from '../../formElements/AcmDate';
import InputFloat from '../../formElements/InputFloat';
import AcmDatetime from '../../formElements/AcmDatetime';
import HiddenRelate from './Fields/HiddenRelate';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';
import customLineSharedFunctions from 'ROOT/src/components/detailView/CustomLines/customLineSharedFunctions'
import {List, Map} from "immutable";
import Pricing from "ROOT/src/components/detailView/Quotes/Pricing";

class CustomLine extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {cols: [], formula: {}, active: false};
        const def = this.props.def;
        def.get('fields').toJS().forEachObject((col) => {
            this[col] = React.createRef();
        });
        this.permissions = this.props.canEdit;
    }

    componentDidMount() {
       this.runCalculationsOnPrefilledLines();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        const data = this.props.data;
        // check if some field needs recalculation (change of whole lines due to changing account on acm_service_order)
        data.forEach((fieldValue, fieldKey) => {
            if(fieldValue === 'RECALCULATE_CUSTOM_LINE'){
                // eslint-disable-next-line no-console
                console.log('run recalculate', fieldValue, fieldKey);
                this.runCalculationsOnPrefilledLines();
            }
        });
    }

    /**
     *
     * @param  {*} direction
     */
    moveElement(direction) {
        this.props.moveElement(this.props.lineKey, direction);
    }

    /**
     *
     * @param {*} key
     * @param {*} showLineButtons
     * @returns {JSX.Element}
     */
    getLineButtons(key, showLineButtons) {
        let eye = null;
        let minWidth = 0;
        let detail = null;
        let deleteIcon = null;
        let orderArrows = null;
        let createMachine = null;
        const module = sAction.dataGet(`${this.props.way}/module`);
        if (sAction.hasAccess(this.props.info.get('module'), 'detail')) {
            if (sAction.hasAccess('acm_machines', 'newRecord') && module === 'acm_orders') {
                minWidth += 14;
                if (this.props.data.get('id')) {
                    createMachine = (
                        <div className="customLinesCellEye">
                            <TooltipWrapper label={'LBL_CREATE_DEVICE'} module='acm_orders_lines'>
                                <div
                                    className="icon-acm_machines detailViewFieldIconEye"
                                    onClick={() => sAction.ordCreateDevice({id: this.props.data.get('id')})}
                                />
                            </TooltipWrapper>
                        </div>
                    );
                } else {
                    createMachine = (<div className='customLinesCellEye'>&nbsp;</div>);
                }
            }
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
                if (this.props.data.get('id')) {
                    eye = (
                        <div className="customLinesCellEye">
                            <TooltipWrapper label={'LBL_OPEN_LINE_IN_RIGHT_PANEL'}>
                                <div
                                    className="icon-eye detailViewFieldIconEye"
                                    onClick={() => this.eyeIconCallback()}
                                />
                            </TooltipWrapper>
                        </div>
                    );
                } else {
                    eye = (<div className='customLinesCellEye'>&nbsp;</div>);
                }
                if (!showLineButtons) {
                    return (
                        <div className='customLinesCell customLinesCellDelete' key={key} style={{minWidth: minWidth + 'px'}}>
                            {eye}
                        </div>
                    );
                }
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
                if (this.props.data.get('id')) {
                    detail = (
                        <div className="customLinesCellEye">
                            <TooltipWrapper label={'LBL_OPEN_LINE_DETAIL_VIEW'}>
                                <a
                                    href={'#detail/' + this.props.info.get('module') + '/' + this.props.data.get('id')}
                                    target="_blank"
                                    rel="noreferrer"
                                    tabIndex={-1}
                                >
                                    <div className="actionPanelButtonIcon icon-editDesc customLinesButton"/>
                                </a>
                            </TooltipWrapper>
                        </div>
                    );
                } else {
                    detail = (<div className='customLinesCellEye'>&nbsp;</div>);
                }
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), 'edit')) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual') {
                minWidth += 14;
                orderArrows = (
                    <div className='customLinesCellEye customLinesCellArrows'>
                        <TooltipWrapper label={'LBL_MOVE_UP'}>
                            <div
                                className="icon-arrowUp prodLineIcon hoverBlue actionPanelButtonIcon"
                                onClick={() => this.moveElement('up')}
                            />
                        </TooltipWrapper>
                        <TooltipWrapper label={'LBL_MOVE_DOWN'}>
                            <div
                                className="icon-arrowDown prodLineIcon hoverBlue actionPanelButtonIcon"
                                onClick={() => this.moveElement('down')}
                            />
                        </TooltipWrapper>
                    </div>
                );
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), 'edit')) {
            minWidth += 16;
            deleteIcon = (
                <div className='customLinesCellEye' style={{marginLeft: '2px'}}>
                    <TooltipWrapper label={'LBL_DELETE_LINE'}>
                        <div
                            className='actionPanelButtonIcon icon-deleteIcon'
                            onClick={() => this.deleteRecord()}
                        />
                    </TooltipWrapper>
                </div>
            );
        }

        return (
            <div className='customLinesCell customLinesCellDelete' key={key} style={{minWidth: minWidth + 'px'}}>
                {orderArrows}
                {createMachine}
                {eye}
                {detail}
                {deleteIcon}
            </div>
        );
    }

    /**
     *
     * @param {*} col
     * @param {*} data
     * @param {*} key
     * @returns {JSX.Element}
     */
    getField(col, data, key) {
        const moduleData = this.props.moduleData;
        const colDef = moduleData.get(col);
        const def = this.props.def;
        let defaultValue = data.get?.(col);
        let className = '';
        let name = colDef.get('name');
        let disabled = this.permissions ? colDef.get('readonly') : true;
        if (this.props.readonly) {
            disabled = this.props.readonly;
        }
        let style = null;
        let timelineStyle = null;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }
        let extraClass = '';
        if (def.get && def.get('extraClass') && def.get('extraClass').get && def.get('extraClass').get(col)) {
            extraClass = def.get('extraClass').get(col);
        }
        if (this.props.showError === true && colDef.get('required') === true &&
            ((typeof defaultValue === 'string' && defaultValue.trim() === '') || (!defaultValue && defaultValue !== 0))
        ) {
            extraClass += ' error';
        }
        if (this.props.timeline && this.props.timeline.get('field') === col) {
            if (style == null) {
                style = {};
            }
            style.color = 'white';
            style.backgroundColor = this.props.timeline.get('colors').get(defaultValue);
            timelineStyle = {color: 'white'};
        }

        const optionsToSelect = [];
        let IDName;
        switch (colDef.get('type')) {
            case 'bool':
                this[col].current = {value: data[key]};

                // Vzhledem k tomu jak defaultValue chodi jednou jako int jednou jako string jednou jako bool
                // bohuzel musim nechat nestiktni provnani, protoze Checkbox potrebuje bool
                return (
                    <div
                        className={'customLinesCell '+extraClass}
                        key={key}
                        style={style}>
                        <Checkbox
                            checked={+defaultValue === 1}
                            onChange={(e) => {
                                this[col].current.value = e; this.changeVal(e, col);
                            }}
                            id={`${key}_${col}`}
                            disabled={disabled}
                        />
                    </div>
                );
            case 'relate':
                return <Relate
                    key={key}
                    newRecord={false}
                    buttons={[]}
                    containerClassName={'customLinesCell'+extraClass}
                    inputClassName={'withBorder'}
                    containerStyle={style}
                    id={`${key}_${col}`}
                    callback={(e) => {
                        // disabled version
                        // this.changeVal({name: e.name, id: e.id}, col);

                        // sAction.dataSet(
                        //     `${this.props.way}/customData/customLines/lines/${this.props.lineKey}/${colDef.get('id_name')}`, e.name);

                        // NOTE: revert because of servis order
                        this.changeVal(e.id, colDef.get('id_name'));
                        sAction.dataSet(this.props.way + '/customData/customLines/lines/' + this.props.lineKey + '/' + col, e.name);
                    }}
                    module={colDef.get('module')}
                    data={{
                        value: defaultValue || '',
                    }}
                    updateField = {true}
                    disabled={disabled}
                    defaultFilter={colDef?.get('defaultFilter')?.toJS()}
                />;
            case 'enum':
                if (def.customOptions && def.customOptions[col]) {
                    def.customOptions[col].forEachObject((opt) => {
                        optionsToSelect.push({value: opt.value, label: sAction.translate(opt.label)});
                    });
                } else {
                    const options = sAction.app_strings[colDef.get('options')];
                    if (!options) {
                        console.error('Missing enum options', colDef.get('options'));
                    }
                    options.forEachObject((value, key) => {
                        optionsToSelect.push({value: key, label: value});
                    });
                }
                if (defaultValue === null || defaultValue === undefined) {
                    defaultValue = colDef.get('default');
                }

                return (
                    <Select
                        containerClassName={'customLinesCell '+extraClass}
                        className="withBorder"
                        disabled={disabled}
                        key={key}
                        value={defaultValue || ''}
                        myRef={this[col]}
                        id={`${key}_${col}`}
                        onChange={(e) => {
                            this[col].current.value = e.target.value; this.changeVal(e.target.value, col);
                        }}
                        options={optionsToSelect}
                        containerStyle={style}
                        style={timelineStyle || null}
                    />
                );
            case 'multienum':
                // eslint-disable-next-line no-case-declarations
                const listOfOptionsToSelect = [];
                // eslint-disable-next-line react/prop-types
                if (def.customOptions && def.customOptions[col]) {
                    // eslint-disable-next-line react/prop-types
                    def.customOptions[col].forEachObject((opt) => {
                        listOfOptionsToSelect.push({value: opt.value, label: sAction.translate(opt.label)});
                    });
                } else {
                    const options = sAction.app_strings[colDef.get('options')];
                    if (!options) {
                        console.error('Missing multienum options', colDef.get('options'));
                    }
                    options.forEachObject((value, key) => {
                        listOfOptionsToSelect.push({value: key, label: value});
                    });
                }
                if (defaultValue === null || defaultValue === undefined) {
                    defaultValue = colDef.get('default');
                }

                return (
                    <MultiSelect
                        className='customLinesCell acmMultiSelectCustomLines acmMultipleSelectSelectBlockCustomLines'
                        options={listOfOptionsToSelect}
                        containerStyle={style}
                        key={key}
                        valueFromProps={true}
                        disabled={disabled}
                        open={false}
                        ref={key}
                        id={`${key}_${col}`}
                        myRef={this[key]}
                        onBlur={(e) => this.changeVal(!e?'':'^'+e.join('^,^')+'^', col)}
                        defaultValue={defaultValue || ''}
                    />);
            case 'float':
            case 'currency':
                className = ' alignRight';
                if (!defaultValue && defaultValue !== 0 && defaultValue !== '0') {
                    defaultValue = '';
                } else if (typeof defaultValue === 'string' ) {
                    defaultValue = parseFloat(defaultValue).toFixed(2);
                }

                return (
                    <InputFloat
                        containerClassName={'customLinesCell '+extraClass}
                        className={'withBorder'}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        /* key generation: when we update whole store with values, we want rerender it
                            (changing account on acm_service_order and recalculation
                              of all lines from BE with new priceLists)
                        */
                        key={`${this.props.lineKey}__${col}__${defaultValue}`}
                        myRef={this[col]}
                        onBlur={(e) => {
                            this.changeVal(e.target.value, col, true);
                        }}
                        containerStyle={style}
                        id={`${key}_${col}`}
                    />
                );
            case 'decimal':
            case 'int':
                if (!defaultValue && defaultValue !== 0 && defaultValue !== '0') {
                    defaultValue = '';
                } else {
                    defaultValue = Math.round(defaultValue * 100) / 100;
                }

                return (
                    <InputText
                        type={'number'}
                        containerClassName={'customLinesCell '+extraClass}
                        className={'withBorder' + className}
                        disabled={disabled}
                        key={key}
                        value={defaultValue}
                        myRef={this[col]}
                        onChange={(e) => this.changeVal(e.target.value, col, false)}
                        onBlur={(e) => this.changeVal(e.target.value, col, true)}
                        containerStyle={style}
                        id={`${key}_${col}`}
                    />
                );
            case 'date':
                return (
                    <div
                        className={'customLinesCell acmInputContainer '+extraClass}
                        style={style}
                        key={key}
                    >
                        <AcmDate
                            type={'text'}
                            placeholder=' '
                            className="withBorder"
                            disabled={disabled}
                            value={defaultValue || null}
                            myRef={this[col]}
                            onChange={(e) => this.changeVal(e, col)}
                            id={`${key}_${col}`}
                        />
                    </div>
                );
            case 'datetime':
                return (
                    <div
                        className={'customLinesCell acmInputContainer '+extraClass}
                        style={style}
                        key={key}
                    >
                        <AcmDatetime
                            type={'text'}
                            placeholder=' '
                            className="withBorder"
                            disabled={disabled}
                            value={defaultValue || null}
                            myRef={this[col]}
                            onChange={(e) => this.changeVal(e.target.value, col)}
                            id={`${key}_${col}`}
                        />
                    </div>
                );
            case 'hiddenRelate':
                IDName = colDef.get('id_name');
                // eslint-disable-next-line no-case-declarations
                const moduleDataName = moduleData.get(IDName) ? moduleData.get(IDName) : moduleData.get(name);

                return (
                    <HiddenRelate
                        key={key}
                        colDef={colDef}
                        relateDef={moduleDataName}
                        relateName={IDName}
                        col={col}
                        onChange={(e) => this.changeVal(e, col)}
                        extraClass={extraClass}
                        style={style}
                        row={this.props.lineKey}
                        way={this.props.way}
                        defaultValue={defaultValue}
                        def={def}
                        disabled={disabled}
                        id={`${key}_${col}`}
                    />
                );
            case 'pricing_map':
                let displayMapKey =  'displayPricingMap_' + name;
                let component, extraEvents = null;
                let pricingMap = data.get('pricing_map') || '';
                if(pricingMap){
                    pricingMap = JSON.parse(pricingMap || '{}');
                    extraEvents = {
                        onClick: () => {
                            {/* display <Pricing only after clicking on icon*/}
                            this.setState((prevState) => ({[displayMapKey]: !prevState[displayMapKey]}));
                        },
                    }
                    component = <div style={{position: 'absolute'}}><Pricing data={pricingMap} className={'pricingMapLeft'}/></div>;
                }
                return (
                    <div className={`customLinesCell hasPricingMap ${extraClass}`} style={style}>
                        {pricingMap ? (
                            <div {...extraEvents}>
                                {/* display <Pricing only after clicking on icon*/}
                                {this.state[displayMapKey] ? component : null}
                                <div
                                    className={`icon icon-info`}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            case 'id':
            case 'text':
            case 'name':
            case 'varchar':
            default:
                return (
                    <InputText
                        type={'text'}
                        containerClassName={'customLinesCell '+extraClass}
                        className="withBorder"
                        disabled={disabled}
                        key={key}
                        value={defaultValue || ''}
                        myRef={this[col]}
                        onChange={(e) => this.changeVal(e.target.value, col, false)}
                        onBlur={(e) => this.changeVal(e.target.value, col, true)}
                        containerStyle={style}
                        id={`${key}_${col}`}
                    />
                );
        }
    }

    /**
     * doCustomLogic - provolava dalsi custom fce, vzniklo kvuli castemu provolavani onChange u varcharu
     * @param {*} val new value for column
     * @param {string} key column name
     * @param {boolean} doCustomLogic
     * @param lineKey
     */
    changeVal(val, key, doCustomLogic = true, lineKey = this.props.lineKey) {
        let valToSave = val;
        if (typeof val === 'object' && val !== null && val.name !== undefined) {
            valToSave = val.name;
        }
        this.saveValue(key, valToSave, lineKey);
        if (doCustomLogic) {
            this.callCustomClassUpdate(key, val);

            // Starsi funkcionalita. Nechavam kvuli zpetne kompatibilite + potencialnimu budoucimu predelani
            this.doSwitch(key, valToSave);
            // I have to setTimeout because if it is not set on new lines in opportunities
            // when new product is selected the cena_bez_dph_celkem will not be set and will stay at 0
            setTimeout(() => {
                this.doMath(key);
            }, 1);

            // NOTE: might be needed for servis order
            // sAction.custom('update', { field: key, value: val, prevValue: null, prefix: this.props.way,
            // module: sAction.dataGet(this.props.way + '/module')});
        }
    }

    /**
     *
     * @param {*} key
     * @param {*} val
     */
    callCustomClassUpdate(key, val) {
        const data = {
            field: key,
            value: val,
            // prevValue v soucasne dobe neni podporovana. Muselo by se kompletne predelat renderovani fieldu.
            prevValue: null,
            moduleData: this.props.moduleData,
            def: this.props.def,
            module: sAction.dataGet(this.props.way + '/module'),
            prefix: this.props.way + '/customData/customLines/lines/' + this.props.lineKey,
            saveField: (val, key, doCustomLogic = true) => {
                // if (this?.[key]?.current) { // save value to input for proper initial calculations
                //     this[key].current.value = val;
                // } else {
                //     this[key].current = {value: val};
                // }
                this.changeVal(val, key, doCustomLogic);
            },
        };
        sAction.custom('updateDetailLine', data);
    }

    /**
     *
     * @param {*} key
     * @param {*} val
     * @param {*} soft
     */
    doSwitch(key, val, soft = false) {
        const def = this.props.def;
        if (!def.get('switch') || !def.get('switch').get(key)) {
            return;
        }
        if (def.get('switch').get(key) === true) {
            if (!soft) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.formula = {global: val};
                this.setState({formula: {global: val}});
            } else {
                const formula = Object.assign({}, this.state.formula);
                formula['global'] = val;
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.formula = formula;
                // this.setState({ formula: formula });
            }
        } else {
            const formula = Object.assign({}, this.state.formula);
            def.get('switch').get(key).toJS().forEach((colName) => {
                formula[colName] = val;
            });
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.formula = formula;
            if (!soft) {
                this.setState({formula: formula});
            }
        }
    }

    /**
     *
     * @param {*} formulas
     * @param {*} formulaName
     * @returns {*|string|string}
     */
    checkFormulaParent(formulas, formulaName) {
        if (formulas[formulaName]) {
            if (typeof formulas[formulaName] === 'string') {
                formulaName = this.checkFormulaParent(formulas, formulas[formulaName]);
            }

            return formulaName;
        }

        return 'default';
    }

    /**
     *
     * @param {*} key
     * @param {*} formulas
     * @returns {string}
     */
    getFormulaName(key, formulas) {
        let formulaName = 'default';
        if (this.state.formula[key]) {
            formulaName = this.state.formula[key];
        } else if (this.state.formula['global']) {
            formulaName = this.state.formula['global'];
        }
        if (!formulas[key][formulaName]) return 'default';
        if (typeof formulas[key][formulaName] === 'string') {
            formulaName = this.checkFormulaParent(formulas[key], formulas[key][formulaName]);
        }

        return formulaName;
    }

    /**
     *
     * @param {*} key
     * @param {*} formulas
     * @return {*}
     */
    getFormulaReferenceKey(key, formulas) {
        const formulaDefinition = formulas[key];
        if (typeof formulaDefinition === 'string') {
            return this.getFormulaReference(formulaDefinition, formulas);
        }

        return key;
    }

    /**
     *
     * @param {*} keyC
     */
    doMath(keyC) {
        let key = keyC;
        if (!this.props.def.get('formula')) {
            return;
        }

        const formulas = this.props.def.get('formula').toJS();
        if (!formulas || !formulas[key]) {
            return;
        }
        if (typeof formulas[key] === 'string') {
            key = this.getFormulaReferenceKey(formulas[key], formulas);
        }
        const formulaName = this.getFormulaName(key, formulas);
        if (typeof formulas[key][formulaName] !== 'object') {
            return;
        }

        sAction.dsClear();
        const formula = formulas[key][formulaName];
        formula.forEachObject((formDef, colKey) => {
            const stack = [];
            formDef.forEach((form) => {
                let num;
                switch (form.type) {
                    case 'num':
                        num = parseFloat(form.value);
                        if (isNaN(num)) {
                            num = 0;
                        }
                        stack.push(num);
                        break;
                    case 'var':
                        if (this[form.value].current.value === undefined) {
                            return false;
                        }
                        num = this[form.value].current.value;
                        if (typeof num === 'boolean') {
                            num = num ? 1 : 0;
                        }
                        num = sAction.parseNumber(num); // because float and currency can be string with spaces
                        if (isNaN(num)) {
                            num = 0;
                        }
                        stack.push(num);
                        break;
                    case 'op':
                        if (typeof stack[stack.length - 1] === 'number' && typeof stack[stack.length - 2] === 'number') {
                            switch (form.value) {
                                case '+':
                                    stack[stack.length - 2] = stack[stack.length - 2] + stack.pop();
                                    break;
                                case '-':
                                    stack[stack.length - 2] = stack[stack.length - 2] - stack.pop();
                                    break;
                                case '*':
                                    stack[stack.length - 2] = stack[stack.length - 2] * stack.pop();
                                    break;
                                case '/':
                                    stack[stack.length - 2] = stack[stack.length - 2] / stack.pop();
                                    break;
                                default:
                                    return false;
                            }
                        } else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            });
            if (+stack.length !== 1) {
                return false;
            }
            if (this[colKey] && this[colKey].current) {
                this[colKey].current.value = sAction.formatNumber(stack[0]);
            }
            sAction.dsAdd('set', `${this.props.way}/customData/customLines/lines/${this.props.lineKey}/${colKey}`,
                Math.round(stack[0] * 100) / 100);
            // sAction.dsAdd('set', `${this.props.way}/changes/customData/customLines/${this.props.lineKey}/${colKey}`,
            //    Math.round(stack[0] * 100) / 100);
        });
        sAction.dsProcess();
    }

    /**
     *
     * @param {*} value
     * @returns {string}
     */
    formatCurrency(value) {
        value = (Math.round(value * 100) / 100).toFixed(2);

        return value;
    }

    /**
     *
     * @param {*} key
     * @param {*} value
     * @param lineKey
     */
    saveValue(key, value, lineKey = this.props.lineKey) {
        sAction.dsClear();
        // // eslint-disable-next-line no-console
        console.log('saveValue',key, value, `${this.props.way}/customData/customLines/lines/${lineKey}/${key}`, value);
        sAction.dsAdd('set', `${this.props.way}/customData/customLines/lines/${lineKey}/${key}`, value);
        sAction.dsAdd('set',`${this.props.way}/changes/forceChange`, true);
        sAction.dsProcess();
    }

    /**
     *
     */
    deleteRecord() {
        this.saveValue('deleted', '1');
        // sAction.dsClear();
        // sAction.dsAdd('delete', this.props.way + '/customData/customLines/lines', this.props.lineKey);
        // sAction.dsProcess();
    }

    /**
     *
     */
    eyeIconCallback() {
        const disMod = this.props.info.get('module'); // modul
        const data = this.props.data;
        const id = data.get ? data.get('id') : null;
        const index = this.props.index;
        const rightPanelData = {
            'reloadList': this.props.prefix,
        };
        sAction.setStorage('listViewSearchIndex', index);
        sAction.rightPanelDetail(disMod, id, rightPanelData);
    }

    /**
     *
     * @returns {JSX.Element}
     */
    getEyeIcon() {
        if (sAction.hasAccess(this.props.info.get('module'), 'detail') && this.props.data.get('id')) {
            return (
                <div className='customLinesCell customLinesCellEye'
                    key='eye'
                >
                    <div className='icon-eye detailViewFieldIconEye'
                        onClick={() => this.eyeIconCallback()}>
                    </div>
                </div>
            );
        }

        return (<div className='customLinesCell customLinesCellEye' key='eye'></div>);
    }

    /**
     *
     */
    toggleActiveState() {
        this.setState({active: !this.state.active});
        this.forceUpdate();
    }

    render() {
        const data = this.props.data;
        const def = this.props.def;
        const moduleData = this.props.moduleData;
        const info = this.props.info;
        const cols = [];

        def.get('fields').toJS().forEachObject((col, key) => {
            cols.push(this.getField(col, data, key));
            if (def.get('switch') && def.get('switch').get(col)) {
                this.doSwitch(col, data.get(col), true);
            }
        });

        if (!sAction.isDesktop()) {
            def.get('fields').toJS().forEachObject((col, key) => {
                const mobileField = <div
                    className={'customLinesCellRoot' +
                        (this.state.active ? ' active' : '') +
                        (col === 'name' ? ' topCell' : '')}

                    style={def.get('style').get(col)?.toJS()} // follow predefined style
                >
                    <label className={'customLinesCellLabel'}>
                        {sAction.translate(moduleData.get(col).get('vname'), info.get('module'))}
                    </label>
                    {this.getField(col, data, key)}
                    <button onClick={() => this.toggleActiveState(this)}
                        className={(this.state.active ? 'icon-subpanelOpen' : 'icon-subpanelClose')}>
                    </button>
                </div>;

                if (col === 'name' || def.get('style').get(col)?.toJS()?.display === 'none') {
                    cols.unshift(mobileField);
                } else {
                    cols.push(mobileField);
                }
            });
        }

        const showLineButtons = (!(this.props.readonly || !this.props.canEdit));
        // const lineButtons = showLineButtons ?
        // this.getLineButtons('deleted', {}, cols.length) : (<div className='customLinesCell customLinesCellDelete'></div>);
        const lineButtons = this.getLineButtons('deleted', showLineButtons);

        return (
            <div className='customLinesRowContainer'>
                {lineButtons}
                {cols}
            </div>
        );
    }
}

/**
 * Add shared functions with servis view
 */
Object.assign(CustomLine.prototype, customLineSharedFunctions);

CustomLine.propTypes = {
    canEdit: PropTypes.any,
    data: PropTypes.any,
    def: PropTypes.any,
    index: PropTypes.any,
    info: PropTypes.any,
    lineKey: PropTypes.number,
    moduleData: PropTypes.any,
    moveElement: PropTypes.func,
    prefix: PropTypes.any,
    readonly: PropTypes.any,
    showError: PropTypes.bool,
    timeline: PropTypes.any,
    way: PropTypes.string,
};

export default CustomLine;
