/**
 * open a new record in right panel
 * @param {string} module module to create a new record in
 * @param {object} initFields initial fields to set, uses detailPredefinedFields
 * @param {object} panelData panel data to set
 * @param {function} callback callback to call after opening the right panel
 */
export default function rightPanelNewRecord(module, initFields = null, panelData = {}, callback = null) {
    // this.closeRightPanel();
    // this reset rightPanel correctly without closing it and reloading list / detail
    this.openRightPanel(null, {});

    if (initFields) {
        this.detailPredefinedFields = initFields;
    }

    this.rightPanelDetail(module, '', panelData, callback);
}
