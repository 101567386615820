import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

/**
 * parent = components/list/NewListViewField
 * @constructor
 * @param {*} props module:  momentalni modul, targetModule: modul na ktery se zvtahuje multirelate pole, value: hodnoty
 * @return {JSX.Element}
 */
export default function Multirelate(props) {
    let values = props.value.get('value');

    if (!values) {
        return (<div className={'newListViewLine ' + props.extraClass}/>);
    }

    values = values.split(',');

    const valuesToRender = [];
    const names = [];
    const ids = [];

    values.forEach((value) => {
        if (value.includes('^')) {
            ids.push(value.replaceAll('^', ''));
            return;
        }
        if (value.includes(';')) {
            names.push(value.replaceAll(';', ','));
            return;
        }
        names.push(value);
    });

    names.forEach((name, index) => {
        let element = <span className={'multiRelateListItem'} key={index} title={name}>{ids[index]}</span>;
        if (sAction.hasAccess(props.module, 'detail')) {
            element = <a className={'multiRelateListItem'} key={index} title={name} href={`#detail/${props.targetModule}/${ids[index]}`}>
                {name}
            </a>;
        }

        valuesToRender.push(element);
    });

    return (
        <div id={props?.fieldId} className={'newListViewLine ' + props.extraClass}>
            {valuesToRender}
        </div>
    );
}

Multirelate.propTypes = {
    extraClass: PropTypes.any,
    fieldId: PropTypes.any,
    module: PropTypes.any,
    targetModule: PropTypes.any,
    value: PropTypes.shape({
        get: PropTypes.func,
    }),
};
