import React from 'react';
import PropTypes from 'prop-types';
import PureComponent from '../pure';

import DropdownAnimation from '../animation/DropdownAnimation';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class viewPanelMenuOthers extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const target = e.target;
        if (!target.classList.contains('actionButtonOthersButton')) {
            this.setState({open: false});
        }
    };
    render() {
        const buttons = this.props.buttons;
        const buttonLimit = this.props.buttonLimit;

        let contentClass = 'actionButtonOthersContent';
        if (buttonLimit > -1) {
            contentClass += ' right';
        }

        return (
            <div className="actionButtonOtherContainer" onClick={(e) => this.handleClickOutside(e)}>
                <TooltipWrapper id='detailMoreOptions' label={'LBL_MORE_OPTIONS'} disabled={this.state.open}>
                    <div
                        className={'icon-More actionButtonOthersButton' + (this.state.open ? ' open' : '')}
                        onClick={() => this.setState({open: !this.state.open})}
                    />
                </TooltipWrapper>
                <DropdownAnimation className={contentClass} open={this.state.open && buttons.length !== 0}>
                    <div className='actionButtonOthersPadding'>
                        {buttons}
                    </div>
                </DropdownAnimation>
            </div>
        );
    }
}

viewPanelMenuOthers.propTypes = {
    buttons: PropTypes.array,
    buttonLimit: PropTypes.number,
};
