import React from 'react';
import p from 'prop-types';
import InputText from './InputText';
import sAction from 'sAction';
import ResizeAnimation from '../animation/ResizeAnimation';
import classnames from 'classnames';

class MultiSelect extends React.Component {
    /** @const arrowIndexOffset - arrowIndex correction*/
    arrowIndexOffset = 1;
    /** @const special key for displaying more values at the end of values for selection */
    openAllOptionsKey = '${}value/}{';
    constructor(props) {
        super(props);

        let selected = null;
        if (props.open === true || !this.dataFrom) {
            this.dataFrom = 'state';
            try {
                if (typeof this.props.defaultValue === 'string') {
                    selected = sAction.parseMultienum(this.props.defaultValue);
                } else {
                    selected = this.props.defaultValue;
                }
            } catch (e) {
                console.error('Failed to parse defaultValue for multienum');
            }
        } else {
            this.dataFrom = 'props';
        }

        this.state = {
            open: props.open ?? false,
            lineHeight: 28,
            limit: 100,
            allResult: false,
            // values, that user selected => negative arrowIndex, when we are moving through them ->
            // index to values must be calculated from this.state.values.length and this.state.arrowIndex
            values: selected,
            searchValue: null,
            arrowIndex: 0,
            expand: false,
        };

        this.searchInterval = null;
        this.input = React.createRef();
        this.arrowItem = React.createRef();
        this.valuesToSelect = [];
    }

    componentDidMount() {
        if (this.input.current != null) {
            this.input.current.focus();
        }
    }

    /**
     * When user moves with arrows and move out of displayed values
     */
    executeScroll = () => {
        if (this.arrowItem.current) {
            this.arrowItem.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        }
    };

    componentWillUnmount() {
        const self = this;
        clearInterval(self.searchInterval);
    }

    /**
     * @desc opens the multienum
     */
    open() {
        if (this.props.readonly) {
            return;
        }
        if (this.state.open === false) {
            this.dataFrom = 'state';
            let selected = [];
            if (this.props.valueFromProps === true) {
                if (this.props.defaultValue != null) {
                    if (typeof this.props.defaultValue === 'string') {
                        selected = sAction.parseMultienum(this.props.defaultValue);
                    } else {
                        selected = this.props.defaultValue;
                    }
                }
            } else {
                selected = this.state.values;
            }
            this.setState({
                values: selected,
            });
            const container = this.container;
            container.style.height = this.state.open ? 'auto' : `${container.clientHeight}px`;
            this.setState({
                open: !this.state.open,
            });
        }
    }

    /**
     * @desc Adds clicked multienum item to selected items
     * @param {string} key
     */
    select(key) {
        const values = this.controlExistingValues([
            ...(this.state.values || []),
            key,
        ]);
        this.setState({
            arrowIndex: 0, // reset position of index after selected values
            values: values,
        });
        this.props.onChange?.(values);
        this.input.current.focus();
    }

    /**
     * @desc deletes already selected value from selected items
     * @param {object} e
     * @param {string} value
     */
    valueDelete(e, value) {
        // Condition to make sure we can not delete from closed multienum
        if (this.state.open) {
            e.stopPropagation();
            const newValues = this.controlExistingValues(
                this.state.values.filter((item) => {
                    return value !== item;
                }),
            );
            this.setState({
                values: newValues,
            });
            this.props.onChange?.(newValues);
            this.input.current.focus();
        }
    }

    /**
     * @desc Blurs focus from multiselect
     * @param {object} e
     */
    cancel(e) {
        this.setState({expand: false});
        if (this.props.readonly) {
            return;
        }
        const relTarget = e.relatedTarget;
        let close = true;
        if (relTarget !== null) {
            const parent = relTarget.closest(
                `div[data-fieldname='${this.props.id}']`,
            );
            if (parent != null || relTarget.dataset.fieldname === this.props.id) {
                this.input.current?.focus();
                close = false;
            }
        }
        if (close) {
            const container = this.container;
            container.style.height = 'auto';
            this.dataFrom = 'props';
            this.setState({
                open: false,
                allResult: false,
                searchValue: null,
            });
            this.props.onBlur?.(this.state.values);
        }
    }

    /**
     * @desc Waits 0.2s then sets search value
     */
    waitForSearch() {
        if (this.searchInterval != null) {
            clearInterval(this.searchInterval);
        }
        const self = this;
        this.searchInterval = setInterval(() => {
            clearInterval(self.searchInterval);
            const value = this.input?.current?.value ?? null;
            value != null ? this.setState({searchValue: value}) : {};
        }, 200);
    }

    /**
     * @desc Expands/collapses multienum based on bool
     * @param {object} e
     * @param {boolean} bool
     */
    expand(e, bool) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({...this.state, expand: bool});
    }

    /**
     * @desc Handles onKeyDown events
     * @param {object} e
     */
    onKeyDown(e) {
        const keyCode = e.keyCode;
        if (keyCode === 40 || keyCode === 38) {
            this.changeArrowIndex(keyCode);
            e.preventDefault();
        }
        this.props.onKeyDown?.(e, this.state.values);
        if (e.keyCode === 13) {
            if (this.state.arrowIndex > 0) {
                // selectValueKey is key from select translation array
                const selectValueKey = this.valuesToSelect[this.state.arrowIndex - this.arrowIndexOffset];
                if (selectValueKey === this.openAllOptionsKey) {
                    // user want open all values, that can be selected
                    this.setState({allResult: true});

                    return;
                }
                const values = this.state.values ?? [];
                values.push(selectValueKey);
                this.setState({
                    values,
                });
            } else {
                const indexDelete = this.state.values?.length + this.state.arrowIndex;
                this.state.values.splice(indexDelete, 1); // delete value from state
                this.setState({
                    values: this.state.values, // update state to force render
                });
            }
        }
    }

    /**
     * @desc Handles keyboard control (up and down arrow movements across items)
     * @param {number} keyCode
     */
    changeArrowIndex(keyCode) {
        let arrowIndex = this.state.arrowIndex;
        keyCode === 40 ? arrowIndex++ : arrowIndex--;
        this.executeScroll();
        if (arrowIndex < 0 && this.state.values.length < -arrowIndex) {
            arrowIndex = arrowIndex + 1; // don't move away from selected values
        } else if (this.props.options != null && arrowIndex > 0 && arrowIndex >= this.valuesToSelect.length) {
            arrowIndex = this.valuesToSelect.length; // don't move away from listed values
        }
        this.setState({arrowIndex});
    }

    /**
     *
     * @param {array} values
     * @return {array}
     */
    controlExistingValues(values) {
        const options = this.props.options.map((option) => option.value);

        return values.filter((value) => options.includes(value));
    }

    /**
     * @desc Generates selected option divs
     * @param {object} element
     * @param {number} index
     * @param {string} className
     * @param {{}} addRef
     * @return {JSX.Element}
     */
    generateLabel(element, index, className, addRef = {}) {
        return (
            <div tabIndex={index + 1} key={index} className={className}>
                <div
                    {...addRef}
                    className="acmMultiSelectDelete"
                    onClick={(e) => this.valueDelete(e, element.value)}
                />
                <div title={element.label}>{element.label}</div>
            </div>
        );
    }

    render() {
        const listStyle = {};
        if (this.state.open === true) {
            // + 1 radek pac prvni je prazdny a neni v seznamu a + 2 za border a vetsi input
            const height = (this.props.options.length + 1) * this.state.lineHeight + 2;
            listStyle['height'] = `${height > 142 ? 142 : height}px`;
            listStyle['display'] = 'flex';
        } else {
            listStyle['height'] = '0px';
            listStyle['display'] = 'none';
        }
        let renderCount = 0;
        let renderCountSelected = 0;
        let SelectedHiddenCount = 0;
        let selected = this.state.values ?? [];

        if (this.props.valueFromProps === true && this.dataFrom === 'props') {
            try {
                if (typeof this.props.defaultValue === 'string') {
                    selected = sAction.parseMultienum(this.props.defaultValue) ?? [];
                } else {
                    selected = this.props.defaultValue ?? [];
                }
            } catch (e) {
                console.error('Failed to parse defaultValue for multienum');
            }
        }
        const selectedRender = this.props.options.filter((element) => selected.indexOf(element.value) !== -1 && element.value !== '')
            .map((element, index) => {
                let addRef = {};
                const selectedItemsArrowSelect = this.state.arrowIndex < 0 &&
                    this.state.values?.[this.state.values?.length + this.state.arrowIndex] === element.value;
                if (selectedItemsArrowSelect) {
                    // for proper scrolling when arrows are used
                    addRef = {ref: this.arrowItem};
                }
                const className = classnames(
                    'acmMultipleSelectSelectBlock',
                    {'arrowSelect': selectedItemsArrowSelect},
                );
                if (!this.state.open && !this.state.expand) {
                    if (renderCountSelected < 3) {
                        renderCountSelected += 1;

                        return this.generateLabel(element, index, className, addRef);
                    } else {
                        SelectedHiddenCount += 1;
                    }
                } else {
                    renderCountSelected += 1;

                    return this.generateLabel(element, index, className, addRef);
                }
            });
        /** move values from this array to this.state.values when they are selected by enter */
        this.valuesToSelect = []; // contains array of keys from select options
        const optionsRender = this.props.options.filter((element) => selected.indexOf(element.value) === -1 &&
            (this.state.searchValue == null || element.label?.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1))
            .map((element, index) => {
                if (renderCount <= this.state.limit || this.state.allResult === true) {
                    renderCount += 1;
                    let addRef = {};
                    const isItemSelected = this.state.arrowIndex >= 0 && this.state.arrowIndex - this.arrowIndexOffset === index;
                    if (isItemSelected) {
                        // for proper scrolling when arrows are used
                        addRef = {ref: this.arrowItem};
                    }
                    this.valuesToSelect.push(element.value);

                    return (
                        <div
                            {...addRef}
                            title={element.label}
                            key={index}
                            className={classnames(
                                'acmMultiSelectListLine',
                                {'arrowSelect': isItemSelected},
                            )}
                            style={{height: this.state.lineHeight + 'px'}}
                            onClick={() => {
                                this.select(element.value);
                            }}
                        >
                            <div data-value={element.value}>
                                {element.label}{' '}
                                {sAction.getStorage('debug') && `[${element.value}]`}
                            </div>
                        </div>
                    );
                }
            });
        if (renderCount > this.state.limit && !this.state.allResult) {
            const isItemSelected = this.valuesToSelect.length <= this.state.arrowIndex - this.arrowIndexOffset;
            this.valuesToSelect.push(this.openAllOptionsKey);
            optionsRender.push(
                <div
                    onClick={() => this.setState({allResult: true})}
                    className={classnames(
                        'acmMultiSelectLastRow',
                        {'arrowSelect': isItemSelected},
                    )}
                    key="last"
                >
                    <b> {sAction.translate('LBL_LOAD_ALL_RESULTS')} </b>
                </div>,
            );
        }

        const searchPanel = (
            <div className="acmMultiSelectSearchPanel">
                <InputText
                    className="acmMultiEnumInput"
                    autoFocus={true}
                    onBlur={(e) => this.cancel(e)}
                    type="text"
                    placeholder={sAction.translate('LBL_MULTIENUM_SEARCH_PLACEHOLDER')}
                    myRef={this.input}
                    tabIndex={100}
                    onKeyUp={(event) => this.waitForSearch(event)}
                />
            </div>
        );
        let expandButton = null;
        if (!this.state.open && this.state.expand) {
            expandButton = (
                <a onClick={(e) => this.expand(e, false)}>
                    <span className={'icon-circleUp'}/>
                    {' ' + sAction.translate('LBL_SHOW_LESS')}
                </a>
            );
        } else if (!this.state.open && !this.state.expand && SelectedHiddenCount > 0) {
            expandButton = (
                <a onClick={(e) => this.expand(e, true)}>
                    <span className={'icon-circleDown'}/>
                    {' ' + sAction.translate('LBL_HIDDEN_OPTIONS') + SelectedHiddenCount}
                </a>
            );
        }

        return (
            <div
                ref={(c) => this.container = c}
                className={classnames('acmMultiSelect', this.props.className ?? '')}
                data-fieldname={this.props.id}
                id={'input_' + this.props.name}
                tabIndex={0}
                onKeyDown={(e) => this.onKeyDown(e)}
            >
                <div
                    className={classnames('acmMultiSelectContainer', {'open': this.state.open})}
                >
                    <div
                        className="acmMultiSelectLabelContainer"
                        onClick={() => this.open()}
                    >
                        <div className="acmMultiSelectLabel">
                            {selectedRender.length === 0 ? (
                                <span className="acmMultiSelectEmpty">
                                    {sAction.translate('LBL_MULTI_SELECT')}
                                </span>
                            ) : ( this.state.open ? (
                                <>
                                    {selectedRender}
                                    {expandButton}
                                </>
                            ) : (
                                <ResizeAnimation
                                    className={'FixedWidth'}
                                    innerClassName={'FixedWidth'}
                                    height={renderCountSelected * 31}
                                >
                                    {selectedRender}
                                    {expandButton}
                                </ResizeAnimation>
                            )
                            )}
                        </div>
                    </div>
                    {this.state.open === true && (
                        <div className="acmMultiSelectListContainer" style={listStyle}>
                            {searchPanel}
                            <div
                                className="acmMultiSelectList"
                            >
                                {optionsRender}
                            </div>
                        </div>
                    )}
                    <input
                        type="hidden"
                        ref={this.props.myRef || undefined}
                        defaultValue={undefined}
                    />
                </div>
            </div>
        );
    }
}

MultiSelect.propTypes = {
    defaultValue: p.oneOfType([p.string, p.array]),
    height: p.number,
    options: p.array.isRequired,
    id: p.string.isRequired,
    onBlur: p.func,
    onChange: p.func,
    onKeyDown: p.func,
    open: p.bool,
    readonly: p.bool,
    valueFromProps: p.bool,
    className: p.string,
    name: p.string,
    myRef: p.shape({current: p.instanceOf(Element)}),
};

export default MultiSelect;
