import React, {useEffect, Suspense, useState} from 'react';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
// import Button from '../formElements/Button';
import sAction from 'sAction';
import ReactReportContextProvider from '../../contexts/ReactReportContext';

export default function ReactReportContainer({name, label, reportType = 'coripoReport', fullscreenId = null}) { // eslint-disable-line
    const [ReportToRender, setReportToRender] = useState(null);

    useEffect(() => {
        const Report = React.lazy(() => import(`../../customReports/reports/${name}/${name}.js`));
        setReportToRender(Report);
        if (reportType === 'coripoReport') {
            document.title = sAction.translate(label, 'acm_coripoReports');
        }
    }, [name, label]);

    return (
        <ReactReportContextProvider reportType={reportType} fullscreenId={fullscreenId}>
            <Suspense fallback={<Loader/>}>
                {ReportToRender &&
                    <ReportToRender title={label}/>}
            </Suspense>
        </ReactReportContextProvider>
    );
}

ReactReportContainer.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    reportType: PropTypes.string,
    fullscreenId: PropTypes.any,
};
