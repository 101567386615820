import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../../pure';
import Relate from '../../../formElements/Relate';
import sAction from 'sAction';

export default class HiddenRelate extends PureComponent {
    /**
     *
     * @param {Object} e
     */
    onChange(e) {
        const props = this.props;
        if (e.id) {
            sAction.dsClear();
            sAction.dsAdd('set', props.way + '/customData/customLines/lines/' + props.row + '/' + props.relateName, e.name);
            sAction.dsAdd('set', props.way + '/customData/customLines/lines/' + props.row + '/' + props.relateDef.get('id_name'), e.id);
            sAction.dsProcess();
        }
        this.props.onChange({name: e.name, id: e.id});
    }

    render() {
        const props = this.props;
        const defaultValue = props.defaultValue;
        const module = props.relateDef?.get?.('module') ? props.relateDef?.get?.('module') : props.colDef.toJS().id_name;

        return (
            <React.Fragment>
                <Relate
                    key={this.props.key}
                    newRecord={false}
                    buttons={[]}
                    containerClassName={'customLinesCell customLinesRelate ' + props.extraClass}
                    containerStyle={props.style}
                    callback={(e) => this.onChange(e)}
                    module={module}
                    data={{
                        value: defaultValue || '',
                    }}
                    updateField={false}
                    disabled={this.props.disabled}
                    id={`hidden_relate_${this.props.id}`}
                />
            </React.Fragment>
        );
    }
}

HiddenRelate.propTypes = {
    disabled: PropTypes.any,
    onChange: PropTypes.func,
    way: PropTypes.string,
    row: PropTypes.any,
    relateDef: PropTypes.object,
    relateName: PropTypes.any,
    colDef: PropTypes.object,
    extraClass: PropTypes.string,
    defaultValue: PropTypes.any,
    style: PropTypes.any,
    id: PropTypes.sting,
};
