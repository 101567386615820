/**
 * Check permission for user logged in
 *
 * @param {string} permission
 * @param {boolean} redirectUnauthorized when true, redirect view to ErrorView component
 * @returns {boolean} true => user fulfills given permission
 * @this {sAction}
 *
 */
export default function checkAdminPermission(permission, redirectUnauthorized = true) {
    switch (permission) {
        case 'isAcmAdmin':
            if (this.isAcmAdmin()) {
                return true;
            }
            break;
        case 'admin':
            if (this.isAdmin()) {
                return true;
            }
            break;
        case 'superUser':
            if (this.isSuperUser()) {
                return true;
            }
            break;
        default:
            console.warn('Undefined permission in checkAdminPermission');
            return false;
    }
    if (redirectUnauthorized) {
        this.dsClear();
        this.dsAdd('set', 'conf/view', 'error');
        this.dsAdd('set', 'view', {
            type: 'unauthorized',
        });
        this.dsProcess();
    }
    return false;
}
