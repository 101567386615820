import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../inputFieldClass';
import Select from '../../formElements/Select';
import sAction from 'sAction';

export default class Enum extends InputField {
    componentDidMount() {}

    /**
     * @param {string|*} value
     * @return boolean
     */
    check(value) {
        const required = this.props.def?.get('required') || false;

        let ok = true;
        if (required === true && value === '') {
            ok = false;
            this.setState({ok});
        }

        return ok;
    }

    /**
     * @param {Event} e
     * @param {string} path
     */
    cancel(e, path) {
        if (path) {
            sAction.dataSet(path + '/actEdit', false);
            if (e.key === 'Tab' || e.key === 'Enter') {
                // pri zmene hodnoty v ciselniku pole ktere je navazano na timeline se ztracel
                // focus po prerendrovani kvuli zmene na timeline
                setTimeout(() => {
                    e.workAsTab = true;
                    super.onKeyDown(e, path, false); // if save is true it will override value, which we selected before
                }, 101);
                return;
            }
        }
        setTimeout(() => {
            document.getElementById('mainDiv')?.focus();
        }, 100);
        if ((e.target.role !== 'option' || e.target.className.includes('Mui-selected')) && this.props.onBlur) {
            this.props.onBlur(this.props.value);
        }
    }

    /**
     * @param {Event} e
     * @param {string} param
     */
    save(e, param = {}) {
        const value = e.target.value;

        if (this.check(value)) {
            if (this.props.way === 'report' && this.props.onBlur) {
                this.props.onBlur(value);

                return;
            }
            const data = {
                way: this.props.way,
                vname: this.props.def?.get('vname'),
                name: this.props.name,
                cancelEdit: true,
                value,
                ...param,
            };

            if (e.keyCode === 13) {
                data['cancelEdit'] = false;
            }

            this.saveField(data);
        }
    }

    /**
     * @param {string|*} value
     * @return {JSX}
     */
    getOptions(value) {
        if (typeof value === 'object') {
            return value;
        }
        return sAction.app_strings[value];
    }
    render() {
        let path = this.props.way + '/rows/' + this.props.rowIndex + '/records/' + this.props.recordIndex;
        if (this.props.rowIndex === undefined || this.props.recordIndex === undefined) {
            path = this.props.way;
        }

        let optionsToSelect = [];
        let colors = null;
        if (this.props.def?.get('customOptions')) {
            optionsToSelect = this.props.def?.get('customOptions').toJS();
        } else {
            const options = Array.isArray(this.props.def?.get('options')) ?
                this.props.def?.get('options') : this.getOptions(this.props.def?.get('options'));
            if (this.props.def?.get('colors')) {
                colors = sAction.app_strings[this.props.def?.get('colors')];
            }

            for (const key in options) {
                // eslint-disable-next-line no-prototype-builtins
                if (options.hasOwnProperty(key)) {
                    const value = options[key];
                    optionsToSelect.push({value: key, label: value});
                }
            }
        }

        const forceOpen = this.props.def?.get('forceOpen') || this.props.listField || this.forceOpen;

        return (
            <Select
                options={optionsToSelect}
                defaultValue={this.props.value}
                onKeyDown={(e) => this.onKeyDown(e, null, false)}
                open={forceOpen === true ? forceOpen : false}
                autoFocus={forceOpen !== true ? true : forceOpen}
                onChange={(e) => this.save(e)}
                onClose={(e) => this.cancel(e, path)}
                colors={colors}
            />
        );
    }
}

Enum.propTypes = {
    way: PropTypes.string.isRequired,
    def: PropTypes.object,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    rowIndex: PropTypes.number,
    recordIndex: PropTypes.number,
    listField: PropTypes.bool,
};
