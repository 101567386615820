import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../formElements/Button';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class File extends React.PureComponent {
    constructor(props) {
        super(props);

        this.canDownload = true;
    }
    newFile = () => {
        sAction.processFileInput({multiple: false, way: this.props.way}, (file) => {
            this.deleteFile();

            sAction.uploadCacheFile(file, (files) => {
                this.canDownload = false;
                const file = files.ids[0];
                sAction.saveField({
                    way: this.props.way,
                    name: this.props.name,
                    type: this.props.def.get('type'),
                    value: file,
                });
            });
        });
    };

    deleteFile = () => {
        let fileId;
        if (this.props.value && this.props.value.indexOf('::') !== -1) {
            [fileId] = this.props.value.split('::');
        } else {
            fileId = this.props.def.getIn(['file', 'id']);
        }

        sAction.saveField({
            way: this.props.way,
            name: this.props.name,
            type: this.props.def.get('type'),
            value: {
                file: {
                    deleteFile: true,
                    id: fileId,
                },
            },
        });
    };

    render() {
        let value = '';
        const readonly = this.props.def.get('readonly') || this.props.readonly;
        if (this.props.value) {
            let fileId; let fileName;
            if (this.props.value.indexOf('::') !== -1) {
                [fileId, fileName] = this.props.value.split('::');
            } else {
                fileId = this.props.def.getIn(['file', 'id']);
                fileName = this.props.value;
            }
            if (this.canDownload) {
                value = (
                    <a
                        className="fileLink"
                        onClick={(e) =>{
                            e.stopPropagation();
                            sAction.downloadFile({
                                id: fileId,
                                module: 'FieldFile',
                                name: fileName,
                                params: sAction.objectToFormdata({forceDownload: true}),
                            });
                        }
                        }
                        title={fileName}
                    >
                        {fileName}
                    </a>
                );
            } else {
                value = <span className="fileName" title={fileName}>{fileName}</span>;
            }
        }

        const deleteFile = (
            <TooltipWrapper label={'LBL_DELETE_BUTTON_LABEL'}>
                <Button className="onlyIconButton blueTextButton" onClick={this.deleteFile}>
                    <div className="icon-deleteIcon" />
                </Button>
            </TooltipWrapper>
        );

        const addFile = (
            <TooltipWrapper label={'LBL_ADD_FILE'} module={'Emails'}>
                <Button className="onlyIconButton blueTextButton" id={`upload_${this.props.name}`} onClick={this.newFile}>
                    <div className="icon-upload" />
                </Button>
            </TooltipWrapper>
        );

        return (
            <div className="detailViewFieldFile">
                {value || <span className="fileName">{sAction.translate('LBL_NO_ATTACHMENT')}</span>}
                {readonly !== true &&
                    <div className="buttons">
                        {value && deleteFile}
                        {addFile}
                    </div>
                }
            </div>
        );
    }
}

File.propTypes = {
    way: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    def: PropTypes.object,
    value: PropTypes.string,
    readonly: PropTypes.bool,
};
