import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            select: null,
            selected: [],
            groupState: {}, // groupId => open/close
            allOpen: false,
        };
        this.tree = React.createRef();
        this.hover = React.createRef();
        this.select = React.createRef();
        this.selectedElementRef = React.createRef();

        this.openLevel = null;
        this.groupInitState = [];
        if (props.openLevel != null) {
            this.openLevel = props.openLevel;
        }
    }
    componentDidMount() {
        if (this.selectedElementRef.current) {
            this.setState({select: this.selectedElementRef.current});
        }
        this.openLevel = null;
        this.setState({
            groupState: this.groupInitState,
        });
    }
    componentDidUpdate() {
        const tree = this.tree.current.querySelector('.acmTreeGroupMain');
        const treeTop = tree.getBoundingClientRect().top;
        if (this.state.select != null) {
            this.select.current.style.display = 'block';
            const targetTop = this.state.select.getBoundingClientRect().top;
            this.select.current.style.top = targetTop - treeTop + tree.scrollTop + 'px';
        } else {
            const main = this.tree.current.querySelector('.acmTreeGroupMain');
            const targetTop = main.getBoundingClientRect().top;
            this.select.current.style.top = targetTop - treeTop + 'px';
            this.select.current.style.display = 'block';
        }
    }

    /**
   *
   * @param {*} group
   * @param {*} first
   * @param {*} level
   * @returns {JSX}
   */
    createGroup(group, first = false, level=0) {
        let open = false;
        if (this.state.groupState[group.id] === 'open' || group.id === 'main') {
            open = true;
        }

        if (this.openLevel != null && level <= this.openLevel) {
            open = true;
            this.groupInitState[group.id] = 'open';
        }

        if (this.state.allOpen === true) {
            open = true;
        }

        const renderTree = [];
        group.children.forEach((child) => {
            if (child.type === 'group') {
                if (child.id === this.props.activeCategoryId) {
                    open = true;
                }
                renderTree.push(this.createGroup(child, false, level+1));
            } else if (child.type === 'record' || child.type === undefined) {
                renderTree.push(this.createRecord(child));
            }
        });
        let module = this.props.module;
        if (module === 'Documents') {
            module = 'acm_documentsCategory';
        } else if (module === 'acm_coripoReports') {
            module = 'acm_reportscategory';
        }

        let ser = false;
        if (this.props.activeCategoryId) {
            ser = group.id === this.props.activeCategoryId;
        }

        return (
            <div
                ref={ser ? this.selectedElementRef : null}
                className={
                    first === true ? 'acmTreeGroup acmTreeGroupMain' : 'acmTreeGroup'
                }
                key={group.id}
            >
                <div className="acmFileLine" />
                <div className="acmTreeContentLine" />
                <div
                    className="acmTreeGroupHeader"
                    onMouseEnter={(e) => this.centerHover(e)}
                    onClick={(e) => this.toggleGroup(e, group.id)}
                >
                    <TooltipWrapper label={'LBL_TREE_CATEGORY'}>
                        <div className="acmTreeFolderIcon icon-folder" />
                    </TooltipWrapper>
                    {this.props.multiple === true && (
                        <input
                            className="acmTreeInput"
                            onChange={(e) => this.addToSelectChange(e, group.id)}
                            type="checkbox"
                        />
                    )}

                    {sAction.translate(group.name, module)}
                </div>
                <div
                    className={
                        open === true ?
                            'acmTreeGroupContent' :
                            'acmTreeGroupContent acmTreeGroupContentClose'
                    }
                >
                    {renderTree}
                </div>
                {first === true &&
        <React.Fragment>
            <div ref={this.hover} className="acmTreeHover" />
            <div ref={this.select} className="acmTreeSelect" />
        </React.Fragment>
                }
            </div>
        );
    }
    /**
   *
   * @param {*} child
   * @returns {JSX}
   */
    createRecord(child) {
        return (
            <div
                className="acmTreeRecord"
                onMouseEnter={(e) => this.centerHover(e)}
                onClick={(e) => this.selectRecord(e, child.id)}
                key={'record' + child.id}
            >
                <div className="acmFileLine" />
                <div className="acmTreeContentLine" />
                <div className="acmTreeFileIcon" />
                {this.props.multiple === true && (
                    <input
                        className="acmTreeInput"
                        onChange={(e) => this.addToSelectChange(e, child.id)}
                        type="checkbox"
                    />
                )}
                <span>{child.name}</span>
            </div>
        );
    }
    /**
   *
   * @param {Object} e
   * @param {*} id
   */
    toggleGroup(e, id) {
        const state = this.state.groupState;
        let data = {};
        if (state[id] === 'close' || state[id] === undefined) {
            state[id] = 'open';
            let target = e.target;
            if (!target.classList.contains('acmTreeGroupHeader')) {
                target = target.closest('.acmTreeGroupHeader');
            }

            data = {groupState: state, select: target};
            if (this.props.onSelectGroup != null) {
                this.props.onSelectGroup(id);
            }
            if (this.props.onChange != null) {
                this.props.onChange(e, 'group', id);
            }
        } else if (state[id] === 'open') {
            if (e.target.classList.contains('acmTreeFolderIcon')) {
                state[id] = 'close';
                data = {groupState: state};
                const group = e.target.closest('.acmTreeGroup');
                const groupHeight = group.clientHeight;
                const tree = this.tree.current.querySelector('.acmTreeGroupMain');
                const y =
          e.target.getBoundingClientRect().top -
          tree.getBoundingClientRect().top +
          tree.scrollTop
          ;
                const yEnd = y + groupHeight;
                const selectY = parseInt(this.select.current.style.top);
                if (selectY > y && selectY < yEnd) {
                    data['select'] = null;
                }
                if (this.props.onChange != null) {
                    this.props.onChange(e, 'group', id);
                }
            } else {
                state[id] = 'open';
                let target = e.target;
                if (!target.classList.contains('acmTreeGroupHeader')) {
                    target = target.closest('.acmTreeGroupHeader');
                }

                data = {groupState: state, select: target};
                if (this.props.onSelectGroup != null) {
                    this.props.onSelectGroup(id);
                }
                if (this.props.onChange != null) {
                    this.props.onChange(e, 'group', id);
                }
            }
        }
        this.setState(data);
    }
    /**
   *
   * @param {Object} e
   */
    centerHover(e) {
        const target = e.target;
        this.hover.current.style.display = 'block';
        const targetTop = target.getBoundingClientRect().top;
        const tree = this.tree.current.querySelector('.acmTreeGroupMain');

        const treeTop = tree.getBoundingClientRect().top;
        const scrollTop = tree.scrollTop;
        this.hover.current.style.top = targetTop - treeTop + scrollTop + 'px';
    }
    /**
   *
   * @param {Object} e
   * @param {*} id
   */
    selectRecord(e, id) {
        let target = e.target;
        if (!target.classList.contains('acmTreeRecord')) {
            target = target.closest('.acmTreeRecord');
        }
        this.setState({select: target});

        if (this.props.onSelectRecord != null) {
            this.props.onSelectRecord(id);
        }
        if (this.props.onChange != null) {
            this.props.onChange(e, 'record', id);
        }
    }
    /**
   *
   */
    closeAll() {
        this.setState({
            select: null,
            groupState: {},
            selected: [],
        });
    }
    /**
   *
   */
    openAll() {
        const childrens = this.props.data;

        const promise = new Promise((resolve) => {
            const ids = {};
            this.openAllRecursive(childrens, ids);
            resolve(ids);
        });
        promise.then((ids) => {
            this.setState({groupState: ids});
        });
    }
    /**
   *
   * @param {Array} groups
   * @param {Object} pole
   */
    openAllRecursive(groups, pole) {
        groups.forEach((element) => {
            pole[element.id] = 'open';
            this.openAllRecursive(element.children, pole);
        });
    }
    /**
   *
   * @param {Object} e
   */
    addToSelectChange(e) {
        const target = e.target;
        if (target.checked) {
            let parent = e.target.closest('.acmTreeRecord');
            if (parent == null) {
                parent = e.target.closest('.acmTreeGroup');
            }
            console.warn(parent);
        } else {
            console.warn('ne');
        }
    }
    render() {
        const data = this.props.data;
        const tree = this.createGroup({children: data, id: 'main'}, true);

        return (
            <div
                ref={this.tree}
                className={'acmTree'}
                onMouseLeave={() => {
                    this.hover.current.style.display = 'none';
                }}
            >
                <div className="acmTreeMenu">
                    <div className="acmTreeHeader">
                        {this.props.header !== undefined && this.props.header}
                    </div>
                    <div className="acmTreeMenuButtons">
                        <TooltipWrapper label={'LBL_OPENALL_BUTTON_LABEL'}>
                            <div
                                className="acmTreeOpenAllIcon icon-openAll"
                                onClick={() => this.openAll()}
                            />
                        </TooltipWrapper>
                        <TooltipWrapper label={'LBL_CLOSEALL_BUTTON_LABEL'}>
                            <div
                                className="acmTreeCloseAllIcon icon-closeAll"
                                onClick={() => this.closeAll()}
                            />
                        </TooltipWrapper>
                    </div>
                </div>
                {tree}
            </div>
        );
    }
}

Tree.propTypes = {
    data: PropTypes.any,
    header: PropTypes.any,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onSelectGroup: PropTypes.func,
    onSelectRecord: PropTypes.func,
    openLevel: PropTypes.any,
    module: PropTypes.string,
    activeCategoryId: PropTypes.any,
};
