import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import TabContainer from 'ROOT/src/components/formElements/TabContainer';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default function DetailTabsContainer(props) {
    /**
     * @param {*} value
     */
    const tabChange = (value) => {
        sAction.changeDetailTab(value, props.way, props.activeTab);
    };

    const formattedTabs = [];
    props.tabs.forEach((tab) => {
        if (tab.hidden) {
            formattedTabs.push(null);

            return;
        }
        if (!tab.newRecord && !sAction.dataGet(props.way + '/id')) {
            return;
        }
        const req = (tab.error && !tab.active) ? ' error' : '';
        formattedTabs.push((
            <div id={tab?.get('identifier')} className={`detailViewEditTabContainer` + req}>
                {sAction.translate(tab.name.toUpperCase(), props.module)}
            </div>
        ));
    });

    const activeTabDetail = sAction.dataGet(props.way + '/activeTab');

    return (
        <div style={{position: 'relative'}} className={'detailTabsContainer'}>
            <TabContainer
                onChange={(e, value) => tabChange(value)}
                value={activeTabDetail}
                tabs={formattedTabs}
            />
        </div>
    );
}

DetailTabsContainer.propTypes = {
    isBoundModule: PropTypes.bool,
    tabs: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            id: PropTypes.number,
            name: PropTypes.string,
            type: PropTypes.string,
            active: PropTypes.bool,
            hidden: PropTypes.bool,
            rows: ImmutablePropTypes.listOf(
                PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
            ),
        }),
    ),
    module: PropTypes.string,
    way: PropTypes.string,
    activeTab: PropTypes.number,
};
