/**
 * Function is also called from User detail from button (definition is on BE in site/modules/Users/coripoUsers.php)
 *
 * @param {string} target
 * @param {bool} useSSO
 * @this sAction
 * @button DetailView/Users
 */
export default function usrMsgraphSignIn(target = '_self', useSSO = false) {
    this.load();
    this.rest.fetchData('msgraphSignIn', 'POST', {useSSO: useSSO}, false).then((ret) => {
        window.open(ret.data.redirect_url, target);
    }).finally(() => {
        this.unLoad();
    });
}
