import PropTypes from 'prop-types';
import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MobileDatePicker} from '@mui/x-date-pickers';
import * as languages from '@mui/x-date-pickers/locales';
import * as languagesMonthsAndDays from 'date-fns/locale';
import moment from 'moment';
import InputText from './InputText';
import sAction from 'sAction';
import Event from '@mui/icons-material/Event';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class AcmDate extends React.Component {
    constructor(props) {
        super();
        this.state = {
            value: this.entryFormat(props.value),
            open: false,
            yearChange: false,
        };
        this.input = React.createRef();
        this.dateFormat = sAction.getDateFormat('datepicker');
    }

    componentDidMount() {
        this.setState({open: this.props.autoOpen});
    }

    /**
   *
   * @param {Date} date
   * @returns
   */
    entryFormat(date) {
        if (!date) {
            return null;
        }
        return date;
    }
    /**
   *
   * @param {Date} date
   * @param {Boolean} fromInput
   */
    onChange(date, fromInput=false) {
        if (this.props.from === 'iframeReport' || this.props.from === 'listViewHeaderField') {
            // Je potřeba kvůli reportu když se vybírá rok aby se po výběru hned nezavřel
            // plus v hlavicce listu
            setTimeout(() => {
                this.callOnChange(date, fromInput);
            }, 10);
        } else {
            this.callOnChange(date, fromInput);
        }
    }

    /**
   *
   * @param {Date} date
   * @param {Boolean} fromInput
   */
    callOnChange(date, fromInput=false) {
        const dateObject = moment(date, 'DD.MM.YYYY').toDate();
        const setDate = moment(fromInput ? dateObject : date).format('YYYY-MM-DD');
        this.setState({value: dateObject});
        if (!this.state.yearChange) {
            this.props.onChange(setDate === 'Invalid date' ? '' : setDate, this.props.name);
        }
        this.setState({yearChange: false});
    }

    /**
    * @memberof AcmDate
    * @return {void}
    */
    onYearChange() {
        // kvuli tomu aby se nevolal onChange z reportu pri vyberu roku z pohledu roku
        this.setState({yearChange: true});
    }

    /**
     *
     * @param {*} e
     * @param {*} type
     * @param {*} save
     */
    onKeyDown(e, type, save) {
        this.props.onKeyDown(e, type, save);
    }

    render() {
        const prefix = this.props.prefix;
        let value = this.state.value;
        if (this.props.defaultValue) {
            value = this.props.defaultValue;
        }

        const inputDate = moment(value).format('DD.MM.YYYY') === 'Invalid date' ?
            '' : moment(value).format('DD.MM.YYYY');

        const dynamicAttr = {};
        if (this.props.freeFormat) {
            if (inputDate != null) {
                const date = inputDate;
                const dateSplit = date.split('..');
                dateSplit.forEach((actDate, index) => {
                    const d = actDate.split('-');
                    if (d.length === 3) {
                        dateSplit[index] = d[2] + '.' + d[1] + '.' + d[0];
                    }
                });
                value = dateSplit.join('..');
            }
        }

        let className = 'AcmDate ' + this.props.className;
        if (this.props.freeFormat) {
            className += ' hidden';
            dynamicAttr.inputValue = value;
        }
        const currentLanguage = sAction.dataGet('conf/language');

        return (
            <>
                {this.state.open ?
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        localeText={languages[currentLanguage.replace('_', '')]?.components.MuiLocalizationProvider.defaultProps.localeText}
                        adapterLocale={currentLanguage === 'en_us' ? languagesMonthsAndDays.enGB :
                            languagesMonthsAndDays[currentLanguage?.slice(0, 2)]}
                    >
                        <MobileDatePicker
                            className={className}
                            autoOk={true}
                            open={this.state.open}
                            clearable
                            autoFocus={this.props.autoFocus ?? true}
                            onYearChange={() => this.onYearChange()}
                            onChange={(date) => this.onChange(date)}
                            onClose={() =>
                                this.props.onClose != null ? this.props.onClose() : this.setState({open: false})
                            }
                            onFocus={this.props.onFocus}
                            onBlur={this.props.onBlur}
                            placeholder={moment(this.state.value).toDate()}
                            showTodayButton={true}
                            format={this.dateFormat}
                            inputRef={this.input}
                            label={this.props.label}
                            invalidDateMessage={this.props.invalidMessage ? this.props.invalidMessage : ''}
                            {...dynamicAttr}
                            value={this.state.value ? moment(this.state.value).toDate() : null}
                            displayWeekNumber={true}
                            slotProps={{
                                actionBar: {actions: ['clear', 'today', 'cancel', 'accept']},
                                dialog: {onKeyDown: (e) => this.onKeyDown(e, null, false)},
                            }}
                            closeOnSelect={true}
                        />
                    </LocalizationProvider> :
                    <div className="acmDateFreeFormat">
                        <InputText
                            key={value}
                            label={this.props.label}
                            defaultValue={inputDate}
                            onBlur={(e) => this.onChange(e.target.value, true)}
                            onKeyDown={(e, type) => {
                                this.onKeyDown(e, type, false);
                            }}
                            autoFocus={this.props.autoFocus ?? true}
                            id={`${prefix === 'view' ? '' : prefix + '_'}input_${this.props.name}`}
                        />
                        <TooltipWrapper label={'LBL_DATEPICKER_OK'}>
                            <span onClick={() => this.setState({open: true})} className='acmDateFreeFormatIconBox'>
                                <Event className='acmDateFreeFormatIcon'/>
                            </span>
                        </TooltipWrapper>
                    </div>}
            </>
        );
    }
}

AcmDate.propTypes = {
    autoFocus: PropTypes.bool,
    autoOpen: PropTypes.bool,
    className: PropTypes.any,
    defaultValue: PropTypes.any,
    freeFormat: PropTypes.any,
    invalidMessage: PropTypes.any,
    label: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.any,
    value: PropTypes.any,
    views: PropTypes.any,
    module: PropTypes.any,
    way: PropTypes.any,
    from: PropTypes.any,
    name: PropTypes.any,
};
