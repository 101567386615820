import React from 'react';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import RenderError from './RenderError';

export default function ErrorView(props) {
    const data = props.data;
    let subject = '';
    let body = '';
    switch (data.get('type')) {
        case 'unauthorized':
            subject = sAction.translate('LBL_UNAUTHORIZED');
            body = sAction.translate('LBL_UNAUTHORIZED_BODY');
            break;
        case 'notValidId':
            subject = sAction.translate('LBL_NOTVALIDID');
            body = sAction.translate('LBL_NOTVALIDID_BODY');
            break;
        case 'detailViewErrors':
            subject = sAction.translate(data.get('subject'));
            body = sAction.translate(data.get('body'));
            break;
        default:
            subject = sAction.translate(data.get('subject'));
            body = sAction.translate(data.get('body'));
            break;
    }

    return <RenderError body={body} subject={subject}/>;
}

ErrorView.propTypes = {
    data: PropTypes.oneOfType([
        // when we are opening ErrorView
        ImmutablePropTypes.mapContains({
            type: PropTypes.string,
            body: PropTypes.string,
            subject: PropTypes.string,
        }),
        // when we are not opening ErrorView
        ImmutablePropTypes.recordOf({}),
    ]),
};
