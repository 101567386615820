import sAction from 'sAction';
import React from 'react';

/**
 * @param {array} view
 * @param {string} module
 * @param {string} level
 * @param {array} options
 * @param {boolean} showPopup
 * @param {object} callbacks
 */
export default function useSaveEditView(
    view,
    module = 'Accounts',
    level = 'custom',
    options = [],
    showPopup = true,
    callbacks = {
        continueEdit: null,
        continueToDetail: null,
    },
) {
    sAction.load();
    sAction.rest.fetchData('editView/saveEditView', 'POST', {
        view: view,
        module: module,
        level: level,
        options: options,
    }).then(({text}) => {
        sAction.unLoad();
        if (!showPopup) {
            return;
        }
        sAction.popup(sAction.translate(text), {
            header: sAction.translate('LBL_EV_SAVED'),
            buttons: [
                {
                    label: (
                        <>
                            <div className='icon-quill buttonIcon' />
                            {sAction.translate('LBL_EV_CONTINUE_EDIT')}
                        </>
                    ), callback: () => {
                        sAction.popupHide();

                        if (callbacks.continueEdit) {
                            callbacks.continueEdit();
                        }
                    },
                },
                {
                    label: (
                        <>
                            <div className='icon-door buttonIcon' />
                            {sAction.translate('LBL_CONTINUE_TO_DETAIL')}
                        </>
                    ), callback: () => {
                        sAction.popupHide();
                        sAction.route();
                        // DM hot-fix for translation reload
                        location.reload();

                        // Maybe one day someone will use this
                        if (callbacks.continueToDetail) {
                            callbacks.continueToDetail();
                        }
                    },
                },
            ],
        });
    }).catch(({text}) => {
        sAction.unLoad();
        sAction.error(sAction.translate(text));
    });
}
