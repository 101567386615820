import React from 'react';

import ListViewTable2 from '../list/listViewTable2';
import Report from '../CustomReport/Report';
import CalendarDashlet from './widgets/CalendarDashlet';

import sAction from 'sAction';
import ReactReportContainer from 'ROOT/src/components/CustomReport/ReactReportContainer';

/**
 * @parent  src\components\home\HomeColumns.js
 * @props   data, def, way
 *
 * zobrazi obsah budiku podle jeho typu (graf, text...)
 */
export default class HomeWidgetContent extends React.Component {
    constructor(props) {
        super(props);
        const {data, def, way} = props;
        const viewData = data.get('viewData');
        const type = def.get('type');
        if (type === 'view' && !viewData) {
            sAction.homeWidgetRefresh(type, way);
        }
    }

    render() {
        const {data, def, way} = this.props;

        let customClass = '';
        let render = '';
        const type = def.get('type');

        switch (type) {
            // Odstraneno pri upgrade node a reactu (nepouzivane)
            // case "chart":
            //   render = <GenerateChart data={data} />;
            //   break;

            case 'text':
                render = data.get('text');
                break;

            case 'view':

                const viewData = data.get('viewData');
                console.log('viewData..', viewData, data);
                if (!viewData) {
                    render = sAction.translate('LBL_LOADING');
                } else {
                    render = (
                        <ListViewTable2
                            data={viewData}
                            prefix={way + '/data/viewData'}
                            parent={this}
                        />
                    );
                }
                customClass = 'widgetListView';
                break;
            case 'customReport':
                if (def.get('reportType') === 'react') {
                    render = <ReactReportContainer name={def.get('classname')} reportType={'widget'} fullscreenId={data.get('widgetId')} />;
                } else {
                    render = <Report data={data.get('viewData')} reportId={data.get('viewId')} widgetId={data.get('widgetId')} way={way + '/data/viewData'} widget={1}/>;
                }
                // render = <ReportContainer data={data} way={way+"/data/viewData"} reportType={def.get("reportType")} name={def.get("classname")} reportId={data.get("viewId")} />
                customClass = 'widgetCustomReport';
                break;
            case 'calendar':
                const viewDataCal = data.get('viewData');
                if (!viewDataCal) {
                    render = sAction.translate('LBL_LOADING');
                } else {
                    render = <CalendarDashlet
                        data={viewDataCal}
                        reportId={viewDataCal.dashletId}
                        way={way} />;
                }
                break;

            case 'iframe':
                return <iframe className="iframe" src={data.get('url')} />;
                break;

            default:
                render = <p>Neznámý typ budíku: {type}</p>;
        }

        const content = !this.props.chartId ? (
            render
        ) : (
            <div className={this.props.chartId}>{render}</div>
        );

        return <div className={'homeCardContent '+customClass}>{content}</div>;
    }
}
