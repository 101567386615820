/**
 * Clear the report window for a new report
 */
export default function clearReportWindow() {
    this.dsClear();

    this.dsAdd('set', 'reportWindow/alterName', {});
    this.dsAdd('set', 'reportWindow/orderBy', {});
    this.dsAdd('set', 'reportWindow/groupBy', {});
    this.dsAdd('set', 'reportWindow/fieldFunction', {});
    this.dsAdd('set', 'reportWindow/dateGroupFunction', {});
    this.dsAdd('set', 'reportWindow/metadata', {}, 'reportWindowMetaData');
    this.dsAdd('set', 'reportWindow/group', {}, 'reportWindowGroup');
    this.dsAdd('set', 'reportWindow/selectedSavedSearchId', '');
    this.dsAdd('set', 'reportWindow/columns', {}, 'reportWindowColumns');

    this.dsProcess();
}
