import React from 'react';
import TickBox from 'ROOT/src/components/formElements/TickBox';
import sAction from 'sAction';

export default function Pricing(props) {
    const data = props.data;
    const className = props.className ?? '';

    if (Object.keys(data).length === 0 || data.size === 0 || data.length === 0) {
        return null;
    }

    /**
     *
     * @param discountType
     */
    const getDiscountType = (discountType) => {
        if (discountType === '0') {
            return 'ABS';
        } else {
            return '%';
        }
    };

    console.log(data);

    return (
        <div className={`pricingMapWrapper ${className}`}>
            <div className="pricingContainer">
                <div className="pricingHeader">
                    <div className="pricingHeaderContent">
                        <span className="calAdiconBoxTitle">{sAction.translate('LBL_PRICING_PRICE_LIST')}</span>
                    </div>
                </div>
                <div className="pricingContent">
                    <table className="pricingTable">
                        <tbody>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_PRICE_LIST_NAME')}</td>
                                <td className="pricingValue">{data.price_list_name}</td>
                            </tr>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_PRICE_LIST_CODE')}</td>
                                <td className="pricingValue">{data.price_list_code}</td>
                            </tr>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_PRICE_DISCOUNT')}</td>
                                <td className="pricingValue">{parseFloat(data.list_percent_discount)}</td>
                            </tr>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_DISCOUNT_TYPE')}</td>
                                <td className="pricingValue">{getDiscountType(data.item_discount_type)}</td>
                            </tr>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_PRICE_AFTER_DISCOUNT')}</td>
                                <td className="pricingValue">{parseFloat(data.price_after_discount)}</td>
                            </tr>
                            <tr className="pricingLine">
                                <td className="pricingName">{sAction.translate('LBL_PRICING_DO_NOT_DISCOUNT')}</td>
                                <td className="pricingValue"><TickBox checked={!!parseInt(data.do_not_discount)} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
