import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

export default function Multienum(props) {
    const bcColor = props.bcColor;
    const items = props.value ?
        props.value.split(',')
            .map((item) => item.split('^').join(''))
            .filter((item) => !!item) :
        [];
    let extraStyle = {};
    let extraStyleDiv = {};
    let spanClass = '';
    if (bcColor) {
        extraStyle = {backgroundColor: bcColor, color: '#FFF'};
        extraStyleDiv = {color: '#FFF'};
        spanClass = 'listBcColor';
    }

    return (
        <div
            title={props.options ? items.map((item) => props.options[item]).join('\n') : items.map((item) => item).join('\n')}
            className={'newListViewLine ' + props.extraClass}
            style={extraStyleDiv}
            onClick={props.onClick}
            id={props?.fieldId}
        >
            <div className={'w100 withIconContainer'}>
                {!!items.length && <span className={spanClass} style={extraStyle}>
                    {props.options ? props.options[items[0]] : items[0]}
                    {items.length > 1 && ' (+' + (items.length - 1) + ' ' + (items.length > 5 ?
                        sAction.translate('LBL_NEXT_PLURAL') : sAction.translate('LBL_NEXT')) + ')'}
                </span>}
                {props.relationFieldEditIcon}
            </div>
        </div>
    );
}

Multienum.propTypes = {
    bcColor: PropTypes.any,
    extraClass: PropTypes.any,
    fieldId: PropTypes.any,
    onClick: PropTypes.any,
    options: PropTypes.any,
    relationFieldEditIcon: PropTypes.any,
    value: PropTypes.shape({
        split: PropTypes.func,
    }),
};
