import PropTypes from 'prop-types';
import React from 'react';

export default function Date(props) {
    const lineRender = props.value;

    return (
        <div id={props?.fieldId} className={'newListViewLine ' + props.extraClass} onClick={props.onClick}>
            <div className={'w100 withIconContainer'}>
                <span>{lineRender}</span>
                {props.relationFieldEditIcon}
            </div>
        </div>

    );
}

Date.propTypes = {
    extraClass: PropTypes.any,
    fieldId: PropTypes.any,
    onClick: PropTypes.any,
    relationFieldEditIcon: PropTypes.any,
    value: PropTypes.any,
};
