import {useState} from 'react';
import sAction from 'sAction';
import {arrayMove} from '@dnd-kit/sortable';

/**
 * Wrapper for functions handling EditView
 * @return {{
 *     getView: (function(): *[]),
 *     initView: initView,
 *     moveTab: moveTab,
 *     movePanel: movePanel,
 *     moveRow: moveRow,
 *     removeRow: removeRow,
 *     moveField: moveField,
 *     addTab: addTab,
 *     addPanel: addPanel,
 *     addRow: addRow,
 *     renamePanel: renamePanel,
 *     removeTab: removeTab,
 *     removePanel: removePanel,
 *     expandField: expandField,
 *     collapseField: collapseField,
 *     editTab: editTab,
 *     editPanelColumns: editPanelColumns,
 *     loadView: loadView,
 * }}
 */
export default function useEditView() {
    const [view, setView] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [panelIndex, setPanelIndex] = useState(0);
    const [newRowIndex, setNewRowIndex] = useState(0);

    /**
     * @param {array} array
     * @param {number} index
     * @returns array
     */
    const removeAtIndex = (array, index) => {
        return [
            ...array.slice(0, index),
            ...array.slice(index + 1),
        ];
    };

    /**
     * @param {array} array
     * @param {number} index
     * @param {*} el
     * @returns array
     */
    const insertAtIndex = (array, index, el) => {
        return [
            ...array.slice(0, index),
            el,
            ...array.slice(index),
        ];
    };

    /**
     *
     * @param {string} name
     * @param {string|number} columns
     * @param {string} id
     * @returns {{columns: (number|number), name: string, id}}
     */
    const generateFieldData = (name, columns, id) => {
        return {
            name: name || '',
            columns: +columns || 1,
            id,
        };
    };

    /**
     *
     * @param {string|null} label
     * @param {string} module
     * @returns {*}
     */
    const getLabel = (label, module) => {
        return sAction.translate(label?.toUpperCase(), module);
    };

    /**
     * @return {*[]}
     */
    const getView = () => {
        return view;
    };

    /**
     * @param {array} viewData
     * @param {string} module
     */
    const initView = (viewData, module) => {
        let maxTabIndex = 0;
        let maxPanelIndex = 0;

        viewData.map((tab) => {
            maxTabIndex++;

            tab.panels.map(() => maxPanelIndex++);
        });

        viewData = viewData.map((tab, tabIndex) => {
            tab.columns = +tab.columns || 1;
            tab.label = getLabel(tab.name, module);
            tab.name = tab.name || `LBL_TAB_${++maxTabIndex}`;

            tab.panels = tab.panels.map((panel, panelIndex) => {
                panel.columns = panel.columns ? (+panel.columns || 1) : tab.columns;
                panel.label = getLabel(panel.name, module) || '';
                panel.name = panel.name || `LBL_PANEL_${++maxPanelIndex}`;

                panel.rows = panel.rows?.map((row, rowIndex) => {
                    const location = `${tabIndex}/${panelIndex}/${rowIndex}`;
                    let columnsUsed = 0;

                    row.fields = row.fields?.map((field, fieldIndex) => {
                        columnsUsed += field?.columns || 1;

                        return generateFieldData(field?.name, field?.columns, `${location}/${fieldIndex}`);
                    });

                    while (columnsUsed < panel.columns) {
                        row.fields.push(generateFieldData('', 1, `${location}/${columnsUsed}`));

                        columnsUsed++;
                    }

                    return {
                        fields: row.fields,
                        id: `${location}`,
                    };
                }) || {};

                return panel;
            });

            return tab;
        });

        setView(viewData);
        setTabIndex(++maxTabIndex);
        setPanelIndex(++maxPanelIndex);
    };

    /**
     * Handles DnD moving Panel
     * @param {number} from
     * @param {number} to
     */
    const moveTab = (from, to) => {
        setView([...arrayMove(view, from, to)]);
    };

    /**
     * Handles DnD moving Panel
     * @param {bool} swap
     * @param {array} from
     * @param {array} to
     */
    const movePanel = (swap, from, to) => {
        const panels = {
            from: view[from[0]].panels,
            to: view[to[0]].panels,
        };

        if (!swap) {
            panels.from = arrayMove(panels.from, from[1], to[1]);
        } else {
            if (to.length === 1) {
                to.push(0);
            }
            const item = panels.from[from[1]];
            panels.from = removeAtIndex(panels.from, from[1]);
            panels.to = insertAtIndex(panels.to, to[1], item);
        }

        view[from[0]].panels = panels.from;

        if (from[0] !== to[0]) {
            view[to[0]].panels = panels.to;
        }

        setView(view);
    };

    /**
     * Handles DnD moving Row
     * @param {bool} swap
     * @param {array} from
     * @param {array} to
     */
    const moveRow = (swap, from, to) => {
        const rows = {
            from: view[from[0]].panels[from[1]].rows,
            to: view[to[0]].panels[to[1]].rows,
        };

        if (!swap) {
            rows.to = arrayMove(rows.from, from[2], to[2]);
        } else {
            const item = rows.from[from[2]];
            rows.from = removeAtIndex(rows.from, from[2]);
            rows.to = insertAtIndex(rows.to, to[2], item);
        }

        view[from[0]].panels[from[1]].rows = rows.from;
        view[to[0]].panels[to[1]].rows = rows.to;

        setView(view);
    };

    /**
     * Handles DnD moving Panel
     * @param {array} from
     * @param {array} to
     * @param {string} activeId
     */
    const moveField = (from, to, activeId) => {
        const fieldsData = {
            from: view[from[0]]?.panels[from[1]].rows[from[2]].fields,
            to: view[to[0]]?.panels[to[1]].rows[to[2]].fields,
        };

        const field = {
            from: fieldsData.from && fieldsData.from[from[3]],
            to: fieldsData.to && fieldsData.to[to[3]],
        };

        const isFromLeftPanel = from[0] === 'LEFT_PANEL';
        const isToLeftPanel = to[0] === 'LEFT_PANEL';
        const columnsUsedByField = field.from?.columns || 1;
        let fillRowWithBlank = false;

        if (isFromLeftPanel && isToLeftPanel) {
            return;
        } else if (isFromLeftPanel) {
            field.to.name = activeId;
        } else if (isToLeftPanel) {
            field.from.name = undefined;

            fillRowWithBlank = true;
        } else {
            if (field.from?.name && field.to?.name) {
                // Swap fields name
                [field.from.name, field.to.name] = [field.to.name, field.from.name];
            } else {
                // Putting field in empty space
                field.to = field.from;
                field.to.columns = 1;

                fillRowWithBlank = true;
            }
        }

        if (fillRowWithBlank) {
            fieldsData.from.splice(
                from[3],
                1,
                ...(Array(columnsUsedByField))
                    .fill(0)
                    .map(() => {
                        return {name: undefined, columns: 1};
                    }),
            );
        }

        if (!isFromLeftPanel) {
            view[from[0]].panels[from[1]].rows[from[2]].fields = fieldsData.from;
        }

        if (!isToLeftPanel) {
            fieldsData.to[to[3]] = field.to;
            view[to[0]].panels[to[1]].rows[to[2]].fields = fieldsData.to;
        }

        setView(view);
    };

    /**
     * Handles "add tab" button
     */
    const addTab = () => {
        view.push({
            active: false,
            columns: 2,
            id: +view.length + 1,
            hidden: false,
            type: 'standard',
            name: `LBL_TAB_${tabIndex}`,
            label: '',
            panels: [
                {
                    name: `LBL_PANEL_${panelIndex}`,
                    label: '',
                    rows: [],
                },
            ],
        });

        setView(view);
        setTabIndex(tabIndex + 1);
        setPanelIndex(panelIndex + 1);
    };

    /**
     * Handles "add panel" button
     * @param {number} activeTab
     */
    const addPanel = (activeTab) => {
        view[activeTab].panels.push({
            name: `LBL_PANEL_${panelIndex}`,
            label: '',
            columns: 2,
            rows: [],
        });

        setView(view);
        setPanelIndex(panelIndex + 1);
    };

    /**
     * Handles "add row" button
     * @param {array} path
     * @param {number} columns
     */
    const addRow = (path, columns) => {
        let colCounter = 0;
        const maxRowIndex = view[path[0]].panels[path[1]].rows.length;
        const fields = [];
        while (colCounter < columns) {
            fields.push(
                generateFieldData(
                    undefined,
                    1,
                    `${path[0]}/${path[1]}/${maxRowIndex}/${colCounter}`,
                ),
            );
            colCounter++;
        }
        view[path[0]].panels[path[1]].rows.push({fields, id: `newRow${newRowIndex}`});
        setView(view);
        setNewRowIndex(newRowIndex + 1);
    };

    /**
     * @param {number} index
     * @param {number} activeTab
     * @param {string} newName
     * @return {*[]}
     */
    const renamePanel = (index, activeTab, newName) => {
        view[activeTab].panels[index].label = newName;
        setView([...view]);
    };

    /**
     * removes tab
     * @param {number} index
     */
    const removeTab = (index) => {
        view.splice(index, 1);
        setView([...view]);
    };

    /**
     * @param {array} path
     * @return {*[]}
     */
    const removePanel = (path) => {
        view[path[0]].panels = removeAtIndex(view[path[0]].panels, path[1]);
        setView([...view]);
    };

    /**
     * @param {array} path
     * @return {*[]}
     */
    const removeRow = (path) => {
        view[path[0]].panels[path[1]].rows = removeAtIndex(view[path[0]].panels[path[1]].rows, path[2]);
        setView([...view]);
    };

    /**
     * @param {array} path
     * @return {*[]}
     */
    const removeField = (path) => {
        view[path[0]].panels[path[1]].rows[path[2]].fields[path[3]].name = '';
        setView([...view]);
    };

    /**
     * @param {array} path
     * @return {*[]}
     */
    const expandField = (path) => {
        view[path[0]].panels[path[1]].rows[path[2]].fields =
            removeAtIndex(view[path[0]].panels[path[1]].rows[path[2]].fields, path[3] + 1);
        view[path[0]].panels[path[1]].rows[path[2]].fields[path[3]].columns += 1;
        setView([...view]);
    };

    /**
     * @param {array} path
     * @return {*[]}
     */
    const collapseField = (path) => {
        const fieldCols = view[path[0]].panels[path[1]].rows[path[2]].fields[path[3]].columns;
        view[path[0]].panels[path[1]].rows[path[2]].fields = insertAtIndex(
            view[path[0]].panels[path[1]].rows[path[2]].fields,
            path[3] + fieldCols - 1,
            {
                name: undefined,
                columns: 1,
                id: `${path[0]}/${path[1]}/${path[2]}/${path[3] + fieldCols - 1}`,
            },
        );
        view[path[0]].panels[path[1]].rows[path[2]].fields[path[3]].columns -= 1;
        setView([...view]);
    };

    /**
     *
     * @param {number} tab
     * @param {object} tabData
     * @param {number} tabData.columns
     * @param {string} tabData.label
     */
    const editTab = (tab, {columns, label}) => {
        if (label !== view[tab].label) {
            view[tab].label = label;
        }
        columns = +columns;
        if (columns) {
            view[tab].panels.forEach((panel, panelIndex) => {
                editPanelColumns(tab, panelIndex, columns, false);
            });
        }
        setView([...view]);
    };

    /**
     * @param {number} tabI
     * @param {number} panelI
     * @param {number} columns
     * @param {boolean} saveView
     * @TODO INTERNAL
     */
    const editPanelColumns = (tabI, panelI, columns, saveView = true) => {
        columns = +columns;

        if (!columns) {
            return;
        }

        const toReturn = [];
        const panel = view[tabI].panels[panelI];
        const position = {row: 0, col: 0};

        panel.columns = columns;

        panel.rows.map((row, rowI) => {
            const fields = {toNew: [], toDispose: [], toSave: row.fields};

            let colsUsed = fields.toSave.reduce((used, field) => {
                return used + (+field.columns);
            }, 0);

            position.col = 0;

            while (colsUsed !== panel.columns) {
                if (colsUsed > panel.columns) {
                    let index = fields.toSave.length - 1;
                    // Pop fields
                    if (colsUsed === index || fields.toSave[index].columns === 1) {
                        const lastField = fields.toSave.pop();

                        if (lastField.name) {
                            fields.toDispose.unshift(lastField);
                        }
                    } else {
                        // shrink field with multiple columns
                        while (fields.toSave[index] === undefined) {
                            index--;
                        }
                        fields.toSave[index].columns--;
                    }

                    colsUsed--;
                } else {
                    // Push Fields
                    fields.toSave.push(
                        generateFieldData(
                            undefined,
                            1,
                            `${tabI}/${panelI}/${rowI}/${colsUsed}`,
                        ),
                    );

                    colsUsed++;
                }
            }

            toReturn[position.row] = {fields: fields.toSave};
            position.row++;

            while (fields.toDispose.length) {
                if (fields.toNew.length < panel.columns) {
                    fields.toNew.unshift(fields.toDispose.shift());
                }

                if (!fields.toDispose.length || fields.toNew.length >= panel.columns) {
                    toReturn[position.row] = {
                        fields: fields.toNew.map((field) => {
                            field.id = `${tabI}/${panelI}/${position.row}/${position.col}`;

                            position.col += field.columns;
                            return field;
                        }),
                    };

                    // fill with placeholders
                    while (toReturn[position.row].fields.length < panel.columns) {
                        toReturn[position.row].fields.push(
                            generateFieldData(
                                undefined,
                                1,
                                `${tabI}/${panelI}/${position.row}/${position.col}`,
                            ),
                        );

                        position.col++;
                    }

                    position.row++;
                    fields.toNew = [];
                }
            }

            view[tabI].panels[panelI].rows = toReturn;
        });

        if (saveView) {
            setView([...view]);
        }
    };

    /**
     * @param {string} path
     * @param {string} module
     * @param {number} level
     */
    const loadView = (path, module, level) => {
        sAction.load();
        sAction.rest.fetchData(`getView/${module}/${path.replace(/^\//, '')}`, 'GET', level? {level} : null)
            .then(({data}) => {
                initView(data.view, module);
                sAction.unLoad();
            })
            .catch(({text}) => {
                sAction.unLoad();
                sAction.error(sAction.translate(text));
            });
    };

    return {
        getView,
        initView,
        moveTab,
        removeTab,
        movePanel,
        moveRow,
        removeRow,
        moveField,
        addTab,
        addPanel,
        addRow,
        renamePanel,
        removePanel,
        expandField,
        collapseField,
        editTab,
        editPanelColumns,
        loadView,
        removeField,
    };
}
