/**
 * Loads price lists when account is changed
 *
 * @param {object} recordInfo Uprimne netusim co to je za promennou
 * @param {string} prefix
 * @this sAction
 */
export default function reloadPriceLists(recordInfo, prefix) {
    this.load();
    this.rest.fetchData('reloadPriceLists', 'POST', {
        recordInfo: recordInfo,
        productData: this.dataGet(`${prefix}/customData/productData`) ?? {}, // for quotes
        customLines: this.dataGet(`${prefix}/customData/customLines/lines`) ?? {}, // for acm_service_order
    }).then((returnData) => {
        if (returnData.data.accountData) {
            const accountData = returnData.data.accountData;
            const formattedIds = this.encodeMultienum(accountData?.accountMap?.ids);

            const actualLists = this.dataGet(`${prefix}/fields/account_price_lists/def/options/id`).toJS();
            const actualAccountDiscount = this.dataGet(`${prefix}/fields/account_discount/value`);
            if ((actualLists !== accountData?.accountMap?.ids) || (actualAccountDiscount !== accountData?.discount_selection)) {
                this.dsClear();
                this.dsAdd('set', `${prefix}/changes/fields/account_discount`, accountData.discount);
                this.dsAdd('set', `${prefix}/fields/account_discount/value`, accountData.discount);

                this.dsAdd('set', `${prefix}/changes/fields/account_discount_selection`, accountData.discount_selection);
                this.dsAdd('set', `${prefix}/fields/account_discount_selection/value`, accountData.discount_selection);

                this.dsAdd('set', `${prefix}/changes/fields/account_price_lists`, formattedIds);
                this.dsAdd('set', `${prefix}/fields/account_price_lists/value`, formattedIds);
                this.dsAdd('set', `${prefix}/fields/account_price_lists/def/options/id`, accountData.accountMap.ids ?? '');
                this.dsAdd('set', `${prefix}/fields/account_price_lists/def/options/names`, accountData.accountMap.names ?? '');

                this.dsAdd('set', `${prefix}/changes/fields/number_of_price_lists`, accountData.numOfRecords);
                this.dsAdd('set', `${prefix}/fields/number_of_price_lists/value`, accountData.numOfRecords);
                this.dsProcess();
            }
        }

        this.dsClear();
        returnData.data?.productData?.groups?.forEach((group, groupKey) => {
            group?.lines.forEach((line, lineKey) => {
                this.dsAdd('set', `${prefix}/customData/productData/groups/${groupKey}/lines/${lineKey}`, line, 'product');
            });
        });
        if(recordInfo.module === 'acm_service_order'){
            this.dsAdd('set', `${prefix}/customData/customLines/lines`, returnData.data?.customLines);
            this.dsAdd('set', `${prefix}/changes/forceChange`, true);
        }
        this.dsProcess();
        this.unLoad();
    }).catch((response) => {
        console.error("Error - reloadPriceLists", response)
        this.unLoad();
    });
}
