import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render error in component
 *
 * @constructor
 * @param {object} props
 * @returns {Element}
 */
function RenderError(props) {
    return (
        <div className="errorViewContainer">
            <div className="errorView">
                <div className="header">
                    <div className="icon-errorHeaderIcon"></div>
                    {props.subject}
                </div>
                <div className="content">
                    {props.body}
                </div>
            </div>
        </div>
    );
}

RenderError.propTypes = {
    subject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default RenderError;
