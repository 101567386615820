import React from 'react';
import PropTypes from 'prop-types';
import PureComponent from '../pure';
import HomeWidgetHeader from './HomeWidgetHeader';
import HomeWidgetContent from './HomeWidgetContent';

/**
 * zobrazuje sloupce na HP, kde jsou dale boxy na budiky
 */
export default class HomeColumns extends PureComponent {
    render() {
        const way = this.props.way;
        const cols = this.props.data.cols.size;

        const render = [];

        // cyklus - pro kazdy sloupec dat na HP
        this.props.data.cols.forEach((col, colIndex) => {
            const lines = [];

            // cyklus - pro kazdy radek s budikem
            col.rows.forEach((row, rowIndex) => {
                if (!row || !row.def || !row.name) {
                    return;
                }
                // drag and drop skryvani
                const style = {};
                style.display = row.def.get('show') ? 'flex' : 'none';

                // id grafu pro jeho stazeni
                let chartId = '';
                if (row.def.get('type') == 'chart' && row.data.get('source') == 'chartjs') {
                    chartId = 'chart' + colIndex + 'r' + rowIndex;
                }

                // vyska widgetu
                let height = '450px';
                const options = row.data.getIn(['options', 'height']);

                if (options) {
                    if (options.indexOf('px') !== -1 || options.indexOf('%') !== -1) {
                        height = options;
                        height = `calc(${height} - 14px)`; // 14 px je margin mezi boxy
                    }
                    style.maxHeight = height;
                }

                // kdyz uzivatel vybere "automaticka velikost"
                style.minHeight = height;
                if (row.def.get('type') == 'customReport' || row.def.get('type') == 'calendar') {
                    style.height = height;
                }

                // pridani widgetu do pole
                lines.push(
                    <div
                        id={row.data.get('widgetId') ?? ''}
                        key={way + '/' + colIndex + '/rows/' + rowIndex}
                        data-way={way + '/' + colIndex + '/rows/' + rowIndex}
                        className={'homeCard homeDragAndDropItem'}
                        style={style}
                    >
                        <HomeWidgetHeader // hlavicka
                            name={row.name}
                            way={way + '/' + colIndex + '/rows'}
                            index={rowIndex}
                            chartId={chartId}
                            type={row.def.get('type')}
                        />

                        <HomeWidgetContent
                            way={way + '/' + colIndex + '/rows/' + rowIndex}
                            data={row.data}
                            def={row.def}
                            chartId={chartId}
                            key={way + '/' + colIndex + '/rows/' + rowIndex + '/' + cols}
                        />
                    </div>,
                );
            }); // konec cyklu s widgety

            // obaleni vsech radku s widgety do jednotlivych sloupcu
            render.push(
                <div
                    key={way + '/' + colIndex}
                    className="gridColumn homeDragAndDropContainer"
                    data-way={way + '/' + colIndex + '/rows'}
                >
                    {lines}
                    <div className="homeDragAndDropItem" data-way="last" />
                </div>,
            );
        });

        // zobrazeni vsech sloupcu a budiku v nich
        return <div className={'gridContainer flex' + cols}>{render}</div>;
    }
}

HomeColumns.propTypes = {
    data: PropTypes.shape({
        cols: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    way: PropTypes.string.isRequired, // "home/tabs/" + activeId + "/cols"
};
